import { FC } from "react";
import { useForm } from "react-hook-form";

import { Button } from "@mui/material";
import Modal, { ModalProps } from "../../components/ModalDialogue";
import ModalHeader from "../../components/ModalDialogue/Header";
import ModalFooter from "../../components/ModalDialogue/Footer";
import ModalBody from "../../components/ModalDialogue/Body";
import RouteForm from "./RouteForm";

// types
import { RouteFormDataType, RouteOptionsType } from "./types";

type AddRouteModalProps = Omit<ModalProps, "children"> & {
  options: RouteOptionsType,
  onAdd: ( route: RouteFormDataType ) => void,
};

const AddRouteModal: FC<AddRouteModalProps> = ({ options, onAdd, onClose, ...props }) => {
  const form = useForm<RouteFormDataType>({
    mode: "onBlur",
    defaultValues: { name: "", site: "", routeType: "", checkpoints: [] },
  });
  const { handleSubmit } = form;

  const handleClose = () => onClose?.({}, "backdropClick");

  const handleAdd = handleSubmit(( data ) => {
    const orderedCheckpoints = data.checkpoints.map(( item, index ) => ( {
      ...item,
      routeCheckpointPosIdx: index,
    } ) );
    onAdd({ ...data, checkpoints: orderedCheckpoints });
    handleClose();
  });
  
  return (
    <Modal boxProps={{ height: 800 }} onClose={onClose} {...props}>
      <>
        <ModalHeader children="Add Route" />

        <ModalBody>
          <RouteForm form={form} options={options} />
        </ModalBody>

        <ModalFooter>
          <Button variant="text" color="secondary" children="Cancel" onClick={handleClose} />
          <Button variant="contained" sx={{ ml: 2 }} children="Create" onClick={handleAdd} />
        </ModalFooter>
      </>
    </Modal>
  );
};

export default AddRouteModal;
