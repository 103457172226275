import * as R from 'ramda';

// types
import { CheckpointDisplayData } from "../types";

export const getCheckpointDetail = ( apiInstance: API.Client ) => async (
  checkpoint: DataModel.Checkpoint
): Promise<CheckpointDisplayData> => {
  // get Site
  const siteResultIncludePromise = apiInstance.Entity.collection( 'site' ).get(checkpoint.siteRefId);
  
  // get Camera
  const scameraResultIncludePromise = apiInstance.Entity.collection( 'camera' ).get(checkpoint.cameraRefId);

  const [site, camera] = await Promise.all([
    siteResultIncludePromise,
    
    scameraResultIncludePromise,
  ]);

  return {
    ...R.omit( [ 'siteRefId', 'routeRefId', 'cameraRefId' ] )( checkpoint ),
    no: 0,
    site,
    camera,
  } as CheckpointDisplayData;
};

export const getCheckpointApi = ( apiInstance: API.Client ) => async (uid: string): Promise<CheckpointDisplayData | null> => {
  const checkpoint = await apiInstance.Entity.collection( 'checkpoint' ).get(uid);
  if (!checkpoint) return null;

  return await getCheckpointDetail( apiInstance )( checkpoint );
};
