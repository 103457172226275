import React, { useEffect, useState } from "react";
import * as R from 'ramda';
import { Box, Grid, Tabs, Tab, Typography } from "@mui/material";
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import LiveVideoPlayer from "../../components/LiveVideoPlayer";
import ProgressBar from "../../components/ProgressBar";
import NotesCheckpointForm from "./NotesCheckpointForm";
import ReportIncidentForm from "./ReportIncidentForm";
import CheckList from "./Checklist";

import getScheduleForPatrol from "./action/getScheduleForPatrol";
import submitPatrol from "./action/submitPatrol";
import submitIncident from "./action/submitIncident";

import { AccountUserClientType } from "../../clients/AccountUser";
import { BridgeClient } from "../../clients/BridgeApi";
import CompletedPatrol from "./CompletedPatrol";

type Props = {
  apiInterface: API.Client,
  bridgeClient: BridgeClient,
  accountUserClient: AccountUserClientType,
  config: any,
};

const Patrol: React.FC<Props> = ( {
  apiInterface,
  bridgeClient,
  accountUserClient,
  config 
} ) => {
    const { uid, time } = useParams();
    const [ completed, setCompleted ] = useState( false );
    const [ entry, setEntry ] = useState<any>( {
      scheduleUid: uid || '',
      scheduledDateTime: time,
      patrolStartDateTime: dayjs().utc().unix(),
    } );
    const [ petrolDetails, setPetrolDetails ] = useState<any>( [] );
    const [ incidentTypes, setIncidentTypes ] = useState<any>( [] );
    const [actionTab, setActionTab] = useState<'notes' | 'report-incident'>('notes');

    useEffect( () => {
      async function getPatrolDetails(){
        const { user } = await accountUserClient.get() as any;
        if ( uid ) {
          const result: any = await getScheduleForPatrol( apiInterface )( uid );
          if ( user ) {
            result.operator = { uid: user.uid, email: user.username, name: user.displayName };
          }
          setEntry( {
            ...entry,
            operator: result.operator,
            route: result.route,
            site: result.site,
            checkpoints: R.range(
              0, result.checkpoints.length
            ).map( R.always( {} ) ),
          } );
          setPetrolDetails( result.checkpoints );
          setIncidentTypes( result.incidentTypes );
        }
      }
      getPatrolDetails();
    }, [ uid ] );

    const [ checkpointPtr, setCheckpointPtr ] = useState( 0 );
    const [ reportedIncidents, setReportedIncidents ] = useState<Omit<DataModel.Incident, "uid">[]>( [] );
    const [ checklist, setChecklist ] = useState<any[]>( [ ] )
    useEffect( () => {
      if ( petrolDetails[ checkpointPtr ] ) {
        setChecklist( petrolDetails[ checkpointPtr ].checklist );
      }
    }, [ petrolDetails ] );
    
    const [ requestImageCapture, setRequestImageCapture ] = useState( {} );

    const captureImage = () => new Promise( ( resolve ) => {
      const onCapture = ( image: string | null ) => {
        resolve( image );
        setRequestImageCapture( {} );
      };
      setRequestImageCapture(
        { onCapture }
      );
    } );

    const totalCheckpoint = petrolDetails.length;
    const handleChangeStep = async ( e: string, done: () => void ) => {
      if ( e === 'next' || e === 'done' ) {
        const oldCheckpoint = R.clone( entry.checkpoints[ checkpointPtr ] );
        let image = null;
        if ( R.isNil( oldCheckpoint.image ) ) {
          try {
            const imageResponse = await captureImage();
            image = imageResponse;
          } catch ( error ) {
            console.log( error );
            image = null;
          }
        } else {
          image = oldCheckpoint.image;
        }
        
        entry.checkpoints[ checkpointPtr ] = {
          uid: petrolDetails[ checkpointPtr ].uid,
          name: petrolDetails[ checkpointPtr ].name,
          notes: petrolDetails[ checkpointPtr ].notes,
          image,
          timestamp: R.has( 'timestamp', oldCheckpoint )
            ? oldCheckpoint.timestamp
            : dayjs().utc().unix(),
          checklist,
          incidents: [ ...reportedIncidents ],
        };
        setReportedIncidents( [ ] );
        if ( e === 'next' ){
          setEntry( {
            ...entry,
            checkpoints: entry.checkpoints,
          } );
          setCheckpointPtr( checkpointPtr + 1 );
          setChecklist(
            petrolDetails[ checkpointPtr + 1 ].checklist
          );
          done();
          return;
        }
        if ( e === 'done' ) {
          setCompleted( true );
          return;
        }
      }
      setCheckpointPtr( checkpointPtr - 1 );
      setChecklist(
        petrolDetails[ checkpointPtr - 1 ].checklist
      );
      done();
    }

    const handleFinish = async ( done: () => void ) => {
      const completedEntry = {
        ...entry,
        checkpoints: entry.checkpoints,
      };

      await submitPatrol( apiInterface )( {
        ...completedEntry,
        patrolEndDateTime: dayjs().utc().unix(),
      } );
      
      done();
      
      window.close();
    }

    const onNotesChange = async ( notes: string ) => {
      const newPetrolDetails = R.clone( petrolDetails );
      const checkpoint = {
        ...petrolDetails[ checkpointPtr ],
        notes,
      };
      newPetrolDetails[ checkpointPtr ] = checkpoint;
      setPetrolDetails( newPetrolDetails );
    }

    const onIncidentSubmit = async ( incidentData: any ) => {
      const incident = {
        ...incidentData,
        incidentType: R.dissocPath( [ 'priority' ], incidentData.incidentType ),
        priorityType: incidentData.incidentType?.priority,
        imopsSiteId: entry.site.imopsId,
        dateTime: dayjs().utc().unix(),
        operator: entry.operator,
        imopsCameraId: petrolDetails[ checkpointPtr ]
          .camera.imopsId,
        checkpoint: {
          uid: petrolDetails[ checkpointPtr ].uid,
          name: petrolDetails[ checkpointPtr ].name,
          checklist,
        }
      };
      await submitIncident( bridgeClient )( incident );
      setReportedIncidents( [
        ...reportedIncidents, incident
      ] );
    };
    
    if ( completed ) {
      return (
        <CompletedPatrol
          checkpoints={ entry.checkpoints }
          onBack={ () => setCompleted( false ) }
          onFinish= { handleFinish }
        />
      );
    }

    return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 4.5,
            background: "#1E1E2E",
            minHeight: "100vh",
          }}
        >
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', }}>
            <Typography variant="h4" sx={{ fontWeight: 700, }} data-testid={'vp-detail-title'} >
                { entry.route?.name }
            </Typography>
          </Box>

          <Box mt={4} sx={{ p: 2, background: "#27293C", borderRadius: 1 }}>
            <ProgressBar checkpointPtr={checkpointPtr + 1} totalCheckpoint={totalCheckpoint} />
          </Box>

          <Grid
            container
            item
            xs={12}
            mt={1.25}
            spacing={3}
            sx={{ minHeight: 366, flex: '1 !important' }}
          >
            <Grid item xs={12} lg={8}>
              {
                petrolDetails && petrolDetails[ checkpointPtr ] && (
                  <LiveVideoPlayer
                    id={ petrolDetails[ checkpointPtr ].camera.uid }
                    name={ petrolDetails[ checkpointPtr ].camera.name }
                    url={ petrolDetails[ checkpointPtr ].camera.rtspStream }
                    params={ petrolDetails[ checkpointPtr ].camera.rtspParams }
                    selected={ false }
                    fullHeight={ true }
                    config={config}
                    requestImageCapture={ requestImageCapture }
                  />
                )
              }                
            </Grid>
            <Grid
              item
              container
              xs={12}
              lg={4}
              sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
            >
              <Box sx={{ flexGrow: 1, background: "#27293C", borderRadius: 1 }}>
                {
                  petrolDetails && petrolDetails[ checkpointPtr ] && (
                    <CheckList
                      data={[ ...checklist ]}
                      checkpointPtr={checkpointPtr}
                      totalCheckpoint={totalCheckpoint}
                      onListChange={setChecklist}
                      handleChangeStep={handleChangeStep} 
                    />
                  )
                }
              </Box>
              <Box sx={{ background: "#27293C", borderRadius: 1 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={actionTab}
                    onChange={(_, value) => setActionTab(value)}
                  >
                    <Tab label="Notes" value="notes" />
                    <Tab label="Report Incident" value="report-incident" />
                  </Tabs>
                </Box>
                <Box display={actionTab === 'notes' ? 'block' : 'none'}>
                  <NotesCheckpointForm
                    notes={ petrolDetails[ checkpointPtr ]?.notes || "" }
                    onNotesChange={ onNotesChange }
                  />
                </Box>
                <Box display={actionTab === 'report-incident' ? 'block' : 'none'}>
                  <ReportIncidentForm
                    incidentTypes={ incidentTypes }
                    onSubmitIncident={ onIncidentSubmit }
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
      </Box>
    );
};


export default Patrol;