import { useEffect, useState } from "react";

// components
import { Box, Button, Typography } from "@mui/material";
import CheckpointsTable from "./CheckpointsTable";
import AddCheckpointModal from "./AddCheckpointModal";
import EditCheckpointModal from "./EditCheckpointModal";
import { Can } from "../../Context/Ability";

// apis
import {
  getCheckpointsApi,
  addCheckpointApi,
  editCheckpointApi,
  deleteCheckpointApi,
  getCheckpointOptionsApi,
  getCheckpointReferences
} from "./action";

// types
import { CheckpointDisplayData, CheckpointFormData, CheckpointOptionsType } from "./types";

type ManageCheckpointsProps = {
  apiClientInstance: API.Client,
};

const ManageCheckpoints = ( props: ManageCheckpointsProps ) => {
  const { apiClientInstance } = props;
  const [openAddModal, setOpenAddModal] = useState(false);

  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isOptionLoaded, setOptionLoaded] = useState(false);

  const [data, setData] = useState<CheckpointDisplayData[]>([]);
  const [editingData, setEditingData] = useState<CheckpointDisplayData | undefined>();
  const [options, setOptions] = useState<CheckpointOptionsType>({
    sites: [],
    cameras: []
  });

  const handleAdd = (checkpoint: CheckpointFormData) => {
    addCheckpointApi( apiClientInstance )(checkpoint).then((res) => {
      res && setData([...data, { ...res, no: data.length + 1 }]);
    });
  };

  const handleEdit = (checkpoint: CheckpointFormData & { uid: string }) => {
    editCheckpointApi( apiClientInstance )(checkpoint).then((res) => {
      res && setData(data.map(( item ) => ( item.uid !== res.uid ? item : { ...res, no: item.no } )));
    });
  };

  const handleDelete = (uid: string) => {
    deleteCheckpointApi( apiClientInstance )( uid ).then(() => {
      setData(
        data.filter((item) => item.uid !== uid).map((item, index) => ({ ...item, no: index + 1 }))
      );
    });
  };

  // Set flag mounted
  useEffect(() => {
    setMounted(true);
  }, []);

  // get checkpoints from server
  useEffect(() => {
    // Call api only 1 time
    if (mounted) {
      getCheckpointsApi( apiClientInstance ).then((res) => {
        setLoading(false);
        res && setData(res);
      });
    }
  }, [mounted, setData]);

  useEffect(() => {
    // call api get options only when 1 modal opened
    if (!openAddModal && !editingData) return;

    getCheckpointOptionsApi( apiClientInstance ).then((res) => {
      setOptionLoaded(true);
      res && setOptions(res);
    });
  }, [openAddModal, editingData]);

  return (
    <Box display="flex" flexDirection="column" height="100%" p={4} bgcolor="#1E1E2E">
      <Box display="flex" justifyContent="space-between" alignItems="end" mb={1.875}>
        <Typography fontSize={35} fontWeight={700}>
          Manage Checkpoints
        </Typography>
        <Can I="create" a="checkpoint">
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ color: "#FFFFFF" }}
            onClick={() => setOpenAddModal(true)}
          >
            + Add Checkpoint
          </Button>
        </Can>
      </Box>

      <CheckpointsTable loading={loading} data={data} onEdit={(data) => setEditingData(data)} />

      {openAddModal && (
        <AddCheckpointModal
          open={openAddModal}
          options={options}
          onAdd={handleAdd}
          onClose={() => setOpenAddModal(false)}
        />
      )}

      {!!editingData && (
        <EditCheckpointModal
          open={true}
          isOptionLoaded={isOptionLoaded}
          options={options}
          checkpoint={editingData}
          getReferences={ getCheckpointReferences( apiClientInstance ) }
          onEdit={handleEdit}
          onDelete={handleDelete}
          onClose={() => setEditingData(undefined)}
        />
      )}
    </Box>
  );
};

export default ManageCheckpoints;
