import { View, Text, StyleSheet } from '@react-pdf/renderer';
import formatCoords from 'formatcoords';

import Section from './Section';

const summaryStyle = StyleSheet.create( {
  row: {
    flexDirection: 'row'
  },
  label: {
    width: '30vw',
    textAlign: 'left',
    padding: 2,
    backgroundColor: 'rgba(128, 128, 128, 0.4)',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
  },
  field: {
    width: '70vw',
    textAlign: 'left',
    padding: 2,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
  }
} );

const SummarySection = ( props: any ) => {
  const coordStr = 
    formatCoords( props.site.latitude, props.site.longitude )
      .format( 'DD MM ss X', { latLonSeparator: ",", decimalPlaces: 2 } )
      .replaceAll( '″', '"' )
      .replaceAll( '′', '\'' );

  return (
    <Section title={ <Text style={{ padding: 2 }}>REPORT DETAILS</Text> } >
      <View style={ summaryStyle.row }>
        <Text style={ summaryStyle.label }>REPORT TYPE</Text>
        <Text style={ summaryStyle.field }>ROUTINE</Text>
      </View>
      <View style={ summaryStyle.row }>
        <Text style={ summaryStyle.label }>JOB TITLE</Text>
        <Text style={ summaryStyle.field }>{ props?.route?.name }</Text>
      </View>
      <View style={ summaryStyle.row }>
        <Text style={ summaryStyle.label }>GPS Coordinates</Text>
        <Text style={ summaryStyle.field }>
          { coordStr }
        </Text>
      </View>
      <View style={ summaryStyle.row }>
        <Text style={ summaryStyle.label }>SUBMITTED BY</Text>
        <Text style={ summaryStyle.field }>
          { props.operator.name }
        </Text>
      </View>
    </Section>
  );
  
};

export default SummarySection;