import {
  TableBody,
} from '@mui/material';
import LoadingOrEmpty from "./LoadingOrEmpty";
import useTableAccessData from "../../../hooks/useTableAccessData";

import Row from './Row';

import { Table } from "../../../types/components/Table";

export type TableBodyProps<TData> = {
  isLessBorder?: boolean,
  loading?: boolean,
  dataKey: keyof TData,
  columns: Table.Column<TData>[],
  data: TData[],
};

const Body = <TData extends Record<string, any>>( props: TableBodyProps<TData> ) => {
  const {
    isLessBorder,
    loading,
    dataKey,
    columns,
    data,
  } = props;

  const [ dataAccessor ] = useTableAccessData( columns );
  return (
    <TableBody>
      {
        ( loading || !data.length ) && 
        <LoadingOrEmpty loading={ loading } columns={ columns } />
      }
      {
        ( !loading && data.length > 0 ) &&
        data.map( ( row, index ) => {
          const isLastRow = index === data.length - 1;
          return (
            <Row
              key={ index }
              row={ row } 
              columns={ columns }
              isLessBorder={ isLessBorder }
              isLastRow={ isLastRow }
              dataKey={ dataKey }
              dataAccessor={ dataAccessor }
            />
          )
        } )
      }
    </TableBody>
  )
}

export default Body;