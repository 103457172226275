import React from 'react';
import { Can } from '../../Context/Ability';

type RestrictedPageProp = {
  I: string,
  a: string,
  element: JSX.Element,
}

const toolBarLinks = [
  'main',
  'profile',
  'logout'
];

const RestrictedPage = ( props: RestrictedPageProp ) => {
  const { I, a, element } = props; 
  
  if ( !toolBarLinks.includes( a ) ) {
    return (
      <>
        <Can I={ I } a={ a } >
          { element }
        </Can>
      </>
    )
  } 
  return element;
};

export default RestrictedPage;