export default {
  variants: [
    {
      props: { color: "primary" },
      style: {
        "& .MuiOutlinedInput-notchedOutline": {
          color: "#FFFFFF",
          borderColor: "#FFFFFF",
        },
      },
    },
  ],
}