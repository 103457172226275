import { FC, useEffect, useMemo } from "react";

import { Box, IconButton, TableCell } from "@mui/material";
import TablePaper from "../../components/Table/Paper";
import Table from "../../components/Table";
import TablePagination from "../../components/Table/Pagination";
import IconSvg from "../../components/IconSvg";
import { Can } from "../../Context/Ability";

import useTableData from "../../hooks/useTableData";

import { Table as TableType } from '../../types/components/Table';
import { CheckpointDisplayData } from "./types";

export interface CheckpointsTableProps {
  loading: boolean;
  data: CheckpointDisplayData[];
  onEdit: (data: CheckpointDisplayData) => void;
}

const CheckpointsTable: FC<CheckpointsTableProps> = ({ loading, data, onEdit }) => {
  const columns = useMemo<TableType.Column<CheckpointDisplayData>[]>(() => {
    return [
      {
        id: "no",
        sortable: true,
        header: "NO",
        accessor: "no",
        width: 80,
      },
      {
        id: "name",
        sortable: true,
        header: "NAME",
        accessor: "name",
        width: "20%",
      },
      {
        id: "description",
        sortable: true,
        header: "DESCRIPTION",
        accessor: "description",
        width: "30%",
      },
      {
        id: "site",
        sortable: true,
        header: "SITES",
        accessor: (row) => row.site.name,
        width: "20%",
      },
      {
        id: "camera",
        sortable: true,
        header: "CAMERAS",
        accessor: (row) => row.camera.name,
        width: "30%",
      },
      {
        id: "aciton",
        header: "ACTION",
        renderBodyCell: (row, cellProps) => (
          <TableCell {...cellProps} sx={{ py: 0 }}>
            <Can I="edit" a="checkpoint">
              <IconButton onClick={() => onEdit(row)}>
                <IconSvg icon="pen" />
              </IconButton>
            </Can>
          </TableCell>
        ),
        width: 150,
        tableCellProps: { align: "right" },
      },
    ];
  }, [onEdit]);

  const { displayData, order, orderBy, setData, handleSort, paginationProps } =
    useTableData<CheckpointDisplayData>({
      sort: { order: "asc", orderBy: "no" },
      columns,
    });

  useEffect(() => {
    setData(data);
  }, [data, setData]);

  return (
    <TablePaper>
      <Box flexGrow={1}>
        <Table
          loading={loading}
          dataKey="uid"
          order={order}
          orderBy={orderBy}
          columns={columns}
          data={displayData}
          onSort={handleSort}
        />
      </Box>

      <TablePagination {...paginationProps} />
    </TablePaper>
  );
};

export default CheckpointsTable;
