import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const config = {
  api: {
    baseUrl: process.env.REACT_APP_API_BASEURL || '',
    domain: process.env.REACT_APP_API_DOMAIN || '',
    service: process.env.REACT_APP_API_SERVICE || '',
  },
  bridge: {
    baseUrl: process.env.REACT_APP_BRIDGE_BASEURL || '',
  },
  summary: {
    baseUrl: process.env.REACT_APP_SUMMARY_BASEURL || '',
  },
  liveVideo: {
    baseAddress: process.env.REACT_APP_LIVE_VIDEO_ADDRESS || '',
    path: process.env.REACT_APP_LIVE_VIDEO_PATH || ''
  },
  user: {
    baseUrl: process.env.REACT_APP_USER_BASEURL || '',
    domain: process.env.REACT_APP_USER_DOMAIN || '',
    service: process.env.REACT_APP_USER_SERVICE || '',
  },
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App config={ config }/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
