import { FC } from "react";

// components
import {
  Box,
  BoxProps,
  TablePagination as MuiTablePagination,
  TablePaginationProps as MuiTablePaginationProps,
} from "@mui/material";

export type TablePaginationProps = MuiTablePaginationProps & {
  boxProps?: BoxProps;
};

const TablePagination: FC<TablePaginationProps> = ({ boxProps, ...props }) => {
  return (
    // set marginTop -1px to make pagination's borderTop override last cell's borderBottom
    <Box borderTop="1px solid #444454" mt={-1 / 8} {...boxProps}>
      <MuiTablePagination {...props} />
    </Box>
  );
};

export default TablePagination;
