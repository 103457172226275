import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import AppStyleTheme from './AppStyleTheme';
import AppRouter from './AppRouter';
import AppRoutes from './AppRoutes';

import AccountUserClient from './clients/AccountUser';
import Bridge from './clients/BridgeApi';
import AdminAPIClient from './clients/AdminApi';
import Maps from './clients/Maps';
import Entities from './clients/Entities';
import Images from './clients/Images';
import SummaryReport from './clients/SummaryReportApi';

import './App.css';

function App( props: any ) {
  const { config } = props;
  const { domain, service } = config.api;
  const { baseAddress, path } = config.liveVideo;
  const accountUserClient = AccountUserClient( config );
  const apiClientInstance = AdminAPIClient( config )( accountUserClient );
  const bridgeClientInstance = Bridge( config )( accountUserClient );
  const summaryReportInstance = SummaryReport( config )( accountUserClient );
  const apiInterfaceInstance = {
    Map: Maps( apiClientInstance ),
    Entity: Entities( apiClientInstance ),
    Image: Images( apiClientInstance ),
  };
  const appRoutes = AppRoutes( apiInterfaceInstance, accountUserClient, summaryReportInstance );

  return (
    <ThemeProvider theme={ AppStyleTheme }>
      <BrowserRouter>
        <AppRouter
          videoConfig={{ baseAddress, path }}
          apiConfig={{ domain, service }}
          apiClient={ apiInterfaceInstance }
          accountUserClient={ accountUserClient }
          bridgeClient={ bridgeClientInstance }
          routerConfig={ appRoutes }
        />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
