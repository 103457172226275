import { ApiClientType } from "../AdminApi";

export default ( apiClient: ApiClientType ) => (
  {
    get: ( imageName: string ) => (
      apiClient.get( `/image/${ imageName }` )
        .then( ( response: Response ) => (
          response.blob()
        ) )
    ),
    getList: () => (
      apiClient.get( '/image/list' )
        .then( response => response.json() )
    ),
    upload: ( imageName: string, image: string ) => (
      apiClient.postText( `/image/${ imageName }`, image )
        .then( _ => ( imageName ) )
    )
  }
)