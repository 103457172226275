// types
import { useCallback } from "react";

import { Table } from "../types/components/Table";

/**
 * This hook is supporting for accessing data from table's data.
 * using place: [useTableData], [TableBody]
 */
const useTableAccessData = <DataType extends Record<string, any>>(columns: Table.Column<DataType>[]) => {
  const dataAccessor = useCallback(
    (row: DataType, id: keyof DataType, defaultValue = "") => {
      const column = columns.find((column) => column.id === id);
      const accessor = column?.accessor;

      if (accessor) {
        if (typeof accessor === "function") return accessor(row);
        return row[accessor];
      }

      // missing accessor
      return defaultValue;
    },
    [columns]
  );

  const sortAccessor = useCallback(
    (row: DataType, id: keyof DataType) => {
      const column = columns.find((column) => column.id === id);
      return column?.sortAccessor ? column?.sortAccessor(row) : dataAccessor(row, id);
    },
    [columns, dataAccessor]
  );

  return [dataAccessor, sortAccessor];
};

export default useTableAccessData;
