import { FC } from "react";
import { Box, BoxProps } from "@mui/material";

type ModalBodyProps = BoxProps

const ModalBody: FC<ModalBodyProps> = (
  { children, ...props }
) => (
  <Box flexGrow={ 1 } p={ 3 } sx={ { overflowY: "auto", ...props.sx } } { ...props }>
    { children }
  </Box>
);

export default ModalBody;
