import { Weekday, RRule } from "rrule";

const days = [RRule.MO, RRule.WE, RRule.TU, RRule.TH, RRule.FR, RRule.SA, RRule.SU];

const daysShortText = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const toString = (byweekday: (number | Weekday)[] = []) => {
  let rrule = new RRule({ freq: RRule.WEEKLY, interval: 1, byweekday: byweekday.sort() });

  // When "Daily" was selected, byweekday can be undefined or empty
  if (!byweekday.length || byweekday.length === 7) {
    rrule = new RRule({ freq: RRule.DAILY, interval: 1 });
  }

  return rrule.toString().replace("RRULE:", "");
};

const parseToList = ( rrule: string ) => {
  const rruleObject = RRule.parseString( rrule );

  // Selected "Daily"
  if (rruleObject.freq === RRule.DAILY) return days;

  return (rruleObject.byweekday || []) as Weekday[];
}

export const recurrenceUtil = {
  days,
  daysShortText,
  toString,
  parseToList,
}