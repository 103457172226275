import { Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import logo from '../../../assets/logo.png';
import Section from './section/Section';

import TimeSection from './section/Time';
import SummarySection from './section/Summary';
import CheckpointSection from './section/Checkpoints';

type ReportDocumentProp = {
  patrol: any,
};

dayjs.extend( utc );

const pageStyle = StyleSheet.create( {
  page: {
    width: '100vw',
    objectFit: 'contain',
    objectPosition: '50% 50%',
    padding: '11 12 11 13 ',
    fontSize: 12,
    fontFamily: 'Times-Roman',
  }
} );

const ReportDocument = ( props: ReportDocumentProp ) => {
  const { patrol } = props;
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={ pageStyle.page }>
        <View>
          <Image source={ logo } style={{ width: '20vw' }} ></Image>
          <Section title={ <Text style={{ padding: 2 }}>VIRTUAL PATROL SERVICE REPORT</Text> }>
            <Text style={{ padding: 2 }}>
              { dayjs.unix( patrol.patrolStartDateTime ).local().format( 'YYYYMMDDHHmmss' ) }
            </Text>
          </Section>
          <Section title={
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ width: '50%', padding: 2 }}>CLIENT</Text>
              <View style={{ width: '50%', padding: 2 }}></View>
            </View>
          }>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: '50%', padding: 2 }}>
                <Text>{ patrol.site.name }</Text> <br />
                <Text style={{ fontSize: 10 }} >{ patrol.site.address }</Text>
              </View>
              <View style={{ width: '50%', padding: 2 }}>
              </View>
            </View>
          </Section>
          <TimeSection patrolStartDateTime={ patrol.patrolStartDateTime } patrolEndDateTime={ patrol.patrolEndDateTime } />
          <SummarySection route={ patrol.route } site={ patrol.site } operator={ patrol.operator } />
          <CheckpointSection checkpoints={ patrol.checkpoints } operator={ patrol.operator } />
          <Text style={ { backgroundColor: 'rgba( 128, 128, 128, 0.5 )', color: 'white', padding: 2 } }>
            END OF REPORT
          </Text>
        </View>
      </Page>
    </Document>
  )  
};

export default ReportDocument;