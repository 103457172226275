import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

// components
import { Box, Typography } from "@mui/material";
import LogsTable from "./LogsTable";
import LogDetailModal from "./LogDetailModal";

// apis
import { getLogsApi } from "./action";
import { AccountUserClientType } from "../../clients/AccountUser";

type LogsProps = {
  apiClientInstance: API.Client,
  accountUserClient: AccountUserClientType,
};

const Logs = ( props: LogsProps ) => {
  const { apiClientInstance, accountUserClient } = props;

  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);

  // Set flag mounted
  useEffect(() => {
    setMounted(true);
  }, []);

  // get logs from server
  useEffect(() => {
    async function loadData() {
      const { user } = await accountUserClient.get() as unknown as { user: DataModel.User };
      const siteIdsFilter = user.siteIds && user.siteIds.length > 0
        ? user.siteIds
        : [];
      const responseData = await getLogsApi( apiClientInstance )( siteIdsFilter );
      responseData && setData( responseData );
      setLoading( false );
    }
    if (mounted) {
      loadData();
    }
  }, [mounted, setData]);

  return (
    <Box display="flex" flexDirection="column" height="100%" p={4} bgcolor="#1E1E2E">
      <Box display="flex" justifyContent="space-between" alignItems="end" mb={1.875}>
        <Typography fontSize={35} fontWeight={700}>
          Logs
        </Typography>
      </Box>

      <LogsTable loading={loading} data={data} />

      <Routes>
        <Route path=":uid" element={<LogDetailModal apiClientInstance={apiClientInstance} open />} />
        <Route path="/" element={<></>} />
      </Routes>
    </Box>
  );
};

export default Logs;
