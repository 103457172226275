// types
import { UserDisplayData } from "../types";

export const getUsersApi = async ( apiInstance: API.Client ) => {
  const userResults = await apiInstance.Entity.collection( 'user') .getAll();

  return userResults.map(
    (user: DataModel.User, index: number) => ( {
      ...user,
      no: index + 1
    } as UserDisplayData )
  );
};
