import { useEffect, useState } from "react";

// components
import { Box, Button, Snackbar, Alert, Typography } from "@mui/material";
import ReportTable from "./ReportTable";

// apis
import { getPatrolList } from "./action/getPatrolList";
import { SummaryClient } from "../../clients/SummaryReportApi";

type ReportProps = {
  apiClientInstance: API.Client,
  summaryReport: SummaryClient,
};

const Reports = ( props: ReportProps ) => {
  const { apiClientInstance, summaryReport } = props;

  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const [ openToast, setOpenToast ] = useState<boolean>( false );

  // Set flag mounted
  useEffect(() => {
    setMounted(true);
  }, []);

  // get logs from server
  useEffect(() => {
    // Call api only 1 time
    if (mounted) {
      getPatrolList( apiClientInstance ).then(( res: any ) => {
        setLoading( false );
        res && setData( res );
      });
    }
  }, [mounted, setData]);

  const onClickDownload = ( uid: string ) => {
    window.open(
      `/report/${ uid }`,
      '_blank',
      `${ 'toolbar=0,menubar=0,width=' }${ window.screen.availWidth },height=${ window.screen.availHeight }`,
    )
  };

  const onClickRequest = async () => {
    await summaryReport.get();
    setOpenToast( true );
  };

  const onRequestCloseToast = () => {
    setOpenToast( false );
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" p={4} bgcolor="#1E1E2E">
      <Box display="flex" justifyContent="space-between" alignItems="end" mb={1.875}>
        <Typography fontSize={35} fontWeight={700}>
          Reports
        </Typography>
        <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ color: "#FFFFFF" }}
            onClick={onClickRequest}
          >
            Request Summary
          </Button>
          <Snackbar open={openToast} autoHideDuration={6000} onClose={onRequestCloseToast}>
            <Alert onClose={onRequestCloseToast} severity="success" sx={{ width: '100%' }}>
              Summary requested. The summary will be sent to your registered email.
            </Alert>
          </Snackbar>
      </Box>
      <ReportTable loading={loading} data={data} onClickDownload={ onClickDownload }/>
    </Box>
  );
};

export default Reports;
