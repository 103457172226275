import React, { useEffect, useMemo, useState } from "react";

// components
import { TablePaginationProps } from "@mui/material";

// types
import { Table } from "../types/components/Table";
import useTableAccessData from "./useTableAccessData";

export interface UseTableProps<DataType> {
  sort?: { order?: Table.Order; orderBy?: keyof DataType };
  pagination?: {
    /**
     * default: 25
     */
    rowsPerPage?:number;
    /**
     * default: [10, 25, 50]
     */
    rowsPerPageOptions?: TablePaginationProps['rowsPerPageOptions'];
  };
  columns: Table.Column<DataType>[];
}

const useTableData = <DataType extends Record<string, any>>({
  sort,
  pagination,
  columns,
}: UseTableProps<DataType>) => {
  const [ , sortAccessor ] = useTableAccessData(columns);

  const [data, setData] = useState<DataType[]>([]);

  const [order, setOrder] = useState<Table.Order>();
  const [orderBy, setOrderBy] = useState<keyof DataType>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pagination?.rowsPerPage || 25);

  // TablePagination's properties are relatied to many states, which are defined in this hook
  // Because of that, we should define main properties right here
  const paginationProps = {
    ...pagination,
    component: "div",
    count: data.length,
    page,
    rowsPerPage,
    rowsPerPageOptions: pagination?.rowsPerPageOptions || [10, 25, 50],
    onPageChange: (_: React.MouseEvent | null, newPage: number) => setPage(newPage),
    onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      // navigate to the first page when [rowPerPage] was changed
      setPage(0);
    },
  };

  // sort data when relatived dependencies was changed
  const sortedData = useMemo(() => {
    if (!orderBy) return data;

    // [data] will be sorted "in place" by sort(), we should make a copy before sort
    return [...data].sort((a, b) => {
      let comparative = 0;
      // sort by Ascending
      // [comparative] should be changed only if sortAccessor(a) !== sortAccessor(b),
      // comparative = 0 will keep current order of [data]
      if (sortAccessor(a, orderBy) > sortAccessor(b, orderBy)) comparative = 1;
      if (sortAccessor(a, orderBy) < sortAccessor(b, orderBy)) comparative = -1;

      // sort by Descending: inverse order
      if (order === "desc") comparative *= -1;
      return comparative;
    });
  }, [data, order, orderBy, sortAccessor]);

  // slice sorted data per page
  const displayData = useMemo(() => {
    return sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [sortedData, page, rowsPerPage]);

  const handleSort = (id: string) => {
    setOrder(orderBy !== id || order === "asc" ? "desc" : "asc");
    setOrderBy(id);
  };

  // set default sort
  useEffect(() => {
    setOrder(sort?.order);
    setOrderBy(sort?.orderBy);
  }, [sort?.order, sort?.orderBy]);

  return {
    // data
    data,
    displayData,
    setData,

    // sort
    order,
    setOrder,
    orderBy,
    setOrderBy,
    handleSort,

    // pagination
    paginationProps,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
  };
};

export default useTableData;
