import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const loaderSx = {
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0 
};

const Loader = ( ) => (
  <Box component="div" sx={ loaderSx }>
    <Box component="div" sx={ { textAlign: 'center !important' } } >
      <Box mb={ 3 }>
      </Box>
      <CircularProgress />
    </Box>
  </Box>
);

export default Loader;
