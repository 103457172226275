import { getUserApi } from "./getUser";

import { UserFormData } from "../types";
import { AccountUserClientType } from "../../../clients/AccountUser";

export const addUserApi = (
  userAccount: AccountUserClientType,
  apiInstance: API.Client
) => async (data: UserFormData) => {
  const newUserUid = await userAccount.add( data as unknown as JSON );

  const addedUserUid = await apiInstance.Entity.collection( 'user' ).add( {
    ...data,
    uid: newUserUid,
  } );

  return getUserApi( apiInstance )( addedUserUid );
};
