import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Typography, useTheme } from '@mui/material';

export default function CheckboxesGroup({ ind, label, onChange, checked }: any) {
    return (
        <Box sx={{
            display: 'flex', alignItems: 'center', py: 1.25,
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
        }}>
          <FormControlLabel
            sx={{ m: 0 }}
            control={
              <Checkbox 
                sx={{
                  p: 0,
                  '&.MuiCheckbox-colorPrimary': {
                      color: "rgba(255, 255, 255, 0.2)",
                      "&.Mui-checked": {
                        color: '#fff'
                      }
                  },
                  '&.MuiForpabel-colorPrimary': {
                      color: '#fff'
                  },
                }}
                checked={checked} 
                onChange={e => {
                  onChange( ind, e.target.checked );
                } } 
                name={ label } 
              />
            }
            label={
              <Box
                data-testid='checkbox'
                sx={{ display: "flex", alignItems: "center", pb: 0.1 }}
              >
                <Typography component="span" sx={{ ml: 2, mr: 2.5, color: "rgba(255, 255, 255, 0.5)" }}>
                  {ind + 1}
                </Typography>
                <Typography component="span">{ label }</Typography>
              </Box>
            }
          />
        </Box>
    );
}
