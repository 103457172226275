// components
import { TableContainer, Table as MuiTable, TableProps as MuiTableProps } from "@mui/material";
import TableHeader, { TableHeaderProps } from "./Header";
import TableBody from "./Body";

type TableProps<TData> = TableHeaderProps<TData> & {
  loading ?: boolean,
  tableProps ?: MuiTableProps,
  dataKey: keyof TData,
  data: TData[],
};

const Table = <TData extends Record<string, any>>(props: TableProps<TData>) => {
  const {
    isLessBorder,
    loading,
    tableProps,
    dataKey,
    columns,
    data,
    order,
    orderBy,
    onSort,
  } = props;

  return (
    <TableContainer>
      <MuiTable sx={{ tableLayout: "fixed", ...tableProps?.sx }} {...tableProps}>
        <TableHeader
          isLessBorder={isLessBorder}
          columns={columns}
          order={order}
          orderBy={orderBy}
          onSort={onSort}
        />

        <TableBody
          isLessBorder={isLessBorder}
          loading={loading}
          dataKey={dataKey}
          columns={columns}
          data={data}
        />
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
