import { createTheme, ThemeOptions } from "@mui/material/styles";
import createPalette from "@mui/material/styles/createPalette";
import createTypography from "@mui/material/styles/createTypography";

import paletteOptions from "./palette";
import typographyOptions from "./typography";
import componentOptions from "./components";

const palette = createPalette( paletteOptions )

const themeOptions: ThemeOptions = {
  palette,
  typography: createTypography( palette, typographyOptions ),
  components: componentOptions,
};


export default createTheme( themeOptions );