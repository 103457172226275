import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { Weekday } from "rrule";

// components
import { Box, Checkbox, ListItemText, MenuItem, Typography } from "@mui/material";
import CustomFormSelect from "../../components/CustomFormSelect";
import CustomTextField from "../../components/CustomTextField";

// types
import { ScheduleFormData, ScheduleOptions } from "./types";

export interface ScheduleFormProps {
  form: UseFormReturn<ScheduleFormData>;
  options: ScheduleOptions;
}

const ScheduleForm: FC<ScheduleFormProps> = ({ form, options }) => {
  const { control, watch } = form;
  const scheduleRule = watch("scheduleRule");
  const operators = watch("operators");

  return (
    <Box>
      <Typography fontSize={18} lineHeight="22px" fontWeight={700} children="Details" />

      <CustomFormSelect
        fullWidth
        required
        multiple
        label="Select Day *"
        control={control}
        name="scheduleRule"
        rules={{ required: true }}
        // multiple props
        options={ options.days }
        selectedAllText="Day: All"
        getOptionValue={( option: Weekday ) => option.weekday }
        getOptionDisplay={( option: Weekday ) => option.weekday }
      >
        <MenuItem value="all">
          <Checkbox checked={scheduleRule.length === options.days.length} />
          <ListItemText primary="Daily" />
        </MenuItem>

        {options.days.map((weekday) => {
          const foundRule = scheduleRule.find( ( rule ) => rule === weekday.weekday );
          return (
            <MenuItem
              key={weekday.weekday}
              value={weekday.weekday}
              sx={{ "&.Mui-selected": { bgcolor: "transparent" } }}
            >
              <Checkbox checked={ !!foundRule || foundRule === 0 } />
              <ListItemText primary={weekday.toString()} />
            </MenuItem>
          );
        })}
      </CustomFormSelect>

      <CustomTextField
        fullWidth
        required
        label="Time"
        type="time"
        control={control}
        name="scheduleTime"
        rules={{ required: true }}
        sx={{ mt: 3 }}
        inputProps={{ step: 300 /* 5 min */ }}
      />

      <CustomFormSelect
        fullWidth
        required
        label="Route *"
        control={control}
        name="route"
        rules={{ required: true }}
      >
        {/* Will be displayed when Schedule Type options is empty */}
        {!options.routes.length && <MenuItem disabled value={-1} children="No item" />}

        {options.routes.map(({ uid, name }) => (
          <MenuItem key={uid} value={uid} children={name} />
        ))}
      </CustomFormSelect>

      <Typography fontSize={18} lineHeight="22px" fontWeight={700} mt={3}>
        Assign To
      </Typography>

      <CustomFormSelect
        fullWidth
        required
        multiple
        label="Operators *"
        control={control}
        name="operators"
        rules={{ required: true }}
        // multiple props
        options={options.operators}
        selectedAllText="All Operators"
        getOptionValue={ ( option: DataModel.User ) => option.uid}
        getOptionDisplay={ ( option: DataModel.User ) => option.displayName}
      >
        {/* Will be displayed when Schedule Type options is empty */}
        {!options.operators.length && <MenuItem disabled value={-1} children="No item" />}

        <MenuItem value="all">
          <Checkbox checked={operators.length === options.operators.length} />
          <ListItemText primary="All Operators" />
        </MenuItem>

        {options.operators.map(({ uid, displayName }) => (
          <MenuItem key={uid} value={uid} sx={{ "&.Mui-selected": { bgcolor: "transparent" } }}>
            <Checkbox checked={operators.indexOf(uid) > -1} />
            <ListItemText primary={displayName} />
          </MenuItem>
        ))}
      </CustomFormSelect>
    </Box>
  );
};

export default ScheduleForm;
