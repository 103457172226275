import * as R from "ramda";
import dayjs from "dayjs";
import { ScheduleDisplayData } from "../types";

export default ( apiInstance: API.Client ) => async (
  scheduleList: DataModel.Schedule[],
  currentDateTime: number,
) => {
  const relatedRoutesList = await apiInstance.Entity.collection( 'route' ).getQuery( {
    uid: { 
      $in: Array.from( 
        new Set( 
          scheduleList.map( schedule => schedule.routeRefId ) 
        ) 
      )
    } 
  } ) as DataModel.Route[];
  const routeRecordSet = relatedRoutesList.reduce( ( recordSet, route ) => (
    { ...recordSet, [ route.uid ]: route }
  ), {} );

  const scheduleDisplayListPromise = scheduleList.map( async schedule => {
    const route = R.has( schedule.routeRefId, routeRecordSet )
      ? routeRecordSet[ schedule.routeRefId ]
      : await apiInstance.Entity
          .collection( 'route' )
          .get( schedule.routeRefId );

    const scheduleTimestamp = dayjs(
      `${ dayjs.unix( currentDateTime ).local().startOf( 'day' ).format( 'YYYY-MM-DD' ) }T${ schedule.scheduleTime }`
    ).unix();

    return {
      uid: schedule.uid,
      time: scheduleTimestamp,
      route,
      assignedOperatorRefIds: schedule.assignOperatorRefIds,
      scheduleDetail: schedule,
      patrolled: false,
      isPatrolling: false,
    } as ScheduleDisplayData;
  } );

  const scheduleDisplayList = await Promise.all( scheduleDisplayListPromise );

  return scheduleDisplayList;
}