import { getCheckpointApi } from "./getCheckpoint";

// types
import { CheckpointFormData } from "../types";

export const addCheckpointApi = ( apiInstance: API.Client ) => async (data: CheckpointFormData) => {
  const addedCheckpointUid = await apiInstance.Entity.collection( 'checkpoint' ).add({
    name: data.name,
    description: data.description,
    siteRefId: data.site,
    cameraRefId: data.camera,
    checklistItems: data.checklistItems,
  } as unknown as JSON );

  return await getCheckpointApi( apiInstance )( addedCheckpointUid );
};
