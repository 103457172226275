import React, { useState, useEffect } from "react";
import {
  Box, Typography, TextField, Button, Snackbar, Alert
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { AccountUserClientType } from '../../clients/AccountUser';

type ProfileForm = Omit<DataModel.User, "role">;

type ProfileProps = {
  accountUserClient: AccountUserClientType
  apiClient: API.Client,
};

const Profile = ( props: ProfileProps ) => {
  const { accountUserClient, apiClient } = props;
  const [ loading, setLoading ] = useState( false );
  const [ user, setUser ] = useState( {
    uid: '',
    username: '',
    displayName: '',
    role: 'public'
  } );
  const [ successSnackBarMessage, setSuccessSnackBarMessage ] = useState( '' );
  const [ changePasswordLink, setChangePasswordLink ] = useState( '' );
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ProfileForm>({ defaultValues: { displayName: '', username: '' } });

  useEffect( () => {
    async function onLoad( ) {
      const { user } = await accountUserClient.get() as unknown as { user: DataModel.User };
      setUser( user );
      setValue( 'displayName', user.displayName );
      setValue( 'username', user.username );
    }
    onLoad();
  }, [ ] );
  useEffect( () => {
    async function getChangePasswordLink () {
      const link = await accountUserClient.getChangePasswordLink( window.location.href );
      setChangePasswordLink( link );
    }
    getChangePasswordLink();
  }, [ ] );


  const handleSubmitForm = async ( form: ProfileForm ) => {
    setLoading( true );
    await accountUserClient.update( user.uid, form as unknown as JSON );
    if ( user.role !== 'systemAdmin' ) {
      await apiClient.Entity.collection( 'user' )
        .update( user.uid, form as unknown as JSON );
    }
    setSuccessSnackBarMessage( "Profile Updated Successfully." );
    setLoading( false );
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" p={4} bgcolor="#1E1E2E">        
      <Box
        sx={ {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end',
          mb: 1.875
        } }
      >
        <Typography sx={{ fontSize: 35, fontWeight: 700 }}>
          Account Settings
        </Typography>
      </Box>
      <form onSubmit={handleSubmit( handleSubmitForm )} noValidate>
        <Box sx={ { p: 2, pr: 3 } }>
          <Box sx={{ width: '100%', mb: 3 }}>
            <Controller
              control={control}
              name="username"
              rules={{ required: "Username is required" }}
              defaultValue=""
              render={({ field: { onChange, ...field } }) => (
                  <TextField
                    {...field}
                    variant="standard"
                    name="username"
                    onChange={onChange}
                    helperText={errors?.username?.message}
                    error={!!errors?.username}
                    type="text"
                    label="Username"
                  />
              )}
            />
          </Box>
          <Box sx={{ width: '100%', mb: 3 }}>
            <Controller
              control={control}
              name="displayName"
              rules={{ required: "Display Name is required" }}
              defaultValue=""
              render={({ field: { onChange, ...field } }) => (
                  <TextField
                    {...field}
                    variant="standard"
                    name="displayName"
                    onChange={onChange}
                    helperText={errors?.displayName?.message}
                    error={!!errors?.displayName}
                    type="text"
                    label="Display Name"
                  />
              )}
            />
          </Box>
        </Box>
        <Box
          sx={{
            height: 39,
            p: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative',
            bottom: '-11%'
          }}>
            {
              ( changePasswordLink !== null ) && (
                <Button variant="outlined" href={ changePasswordLink }>
                  Change Password
                </Button>
              )
            }
            <Button variant="contained" disabled={ loading } >
              Update
            </Button>
        </Box>
      </form>
      <Snackbar
        open={ successSnackBarMessage !== '' }
        anchorOrigin={ { horizontal: 'center', vertical: 'bottom' } }
        autoHideDuration={ 6000 }
        key="bottom center"
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          { successSnackBarMessage }
        </Alert>
      </Snackbar>
    </Box>
  )
};

export default Profile;