import { FC, useState } from "react";
import { UseFormReturn } from "react-hook-form";

// components
import { Box, MenuItem, Typography, IconButton, InputAdornment, FilledInput } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import StarsRoundedIcon from '@mui/icons-material/StarsRounded';
import CustomFormSelect from "../../components/CustomFormSelect";
import ChecklistCard from "./ChecklistCard";
import CustomTextField from "../../components/CustomTextField";

// types
import { CheckpointFormData, CheckpointOptionsType } from "./types";

export interface CheckpointFormProps {
  form: UseFormReturn<CheckpointFormData>;
  options: CheckpointOptionsType;
}

const CheckpointForm: FC<CheckpointFormProps> = ({ form, options }) => {
  const { control, setValue, watch } = form;
  const checklistItems = watch("checklistItems");
  const siteId = watch("site");

  const handleAddCheckpoint = ( item: string ) => {
    if ( item !== '' ) {
      setValue( "checklistItems", [ ...checklistItems, item ] );
    }
  };

  const handleDeleteCheckpoint = (description: string) => {
    // remove selected checklistItem
    setValue(
      "checklistItems",
      checklistItems.filter((checklistItem) => checklistItem !== description)
    );
  };


  const handleMoveChecklistItem = (checklist: string, direction: "up" | "down") => {
    const index = checklistItems.findIndex((checklistItem) => checklistItem === checklist);
    if (index < 0) return;

    // checklistItem is at the top of the list
    if (index === 0 && direction === "up") return;
    // checklistItem is at the end of the list
    if (index === checklistItems.length - 1 && direction === "down") return;

    const moveToIndex = direction === "up" ? index - 1 : index + 1;

    // clone [checklistItems]
    const updatingChecklistItems = [...checklistItems];

    // swap items to move
    const swap = updatingChecklistItems[index];
    updatingChecklistItems[index] = updatingChecklistItems[moveToIndex];
    updatingChecklistItems[moveToIndex] = swap;
    
    setValue("checklistItems", updatingChecklistItems);
  };

  const availableCameraOptions = siteId 
    ? options.cameras.filter( cameraOption => cameraOption.camera.siteRefId === siteId )
    : [];

  const AddChecklistItem = () => {
    const [ item, setItem ] = useState( '' );
    return (
      <FilledInput
        fullWidth
        sx={{ mt: 2 }}
        onChange={ v => setItem( v.target.value ) }
        name="newChecklistItem"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="add"
              onClick={ () => handleAddCheckpoint( item ) }
            >
              <AddCircle />
            </IconButton>
          </InputAdornment>
        }
      />
    ); 
  }


  return (
    <Box>
      <Typography fontSize={18} lineHeight="22px" fontWeight={700} children="Details" />

      <CustomTextField
        fullWidth
        required
        label="Name"
        sx={{ mt: 2 }}
        control={control}
        name="name"
        rules={{ required: true }}
      />

      <CustomTextField
        fullWidth
        required
        label="Description"
        sx={{ mt: 2 }}
        control={control}
        name="description"
        rules={{ required: true }}
      />

      <CustomFormSelect
        fullWidth
        required
        label="Select a Site *"
        control={control}
        name="site"
        rules={{ required: true }}
      >
        {/* Will be displayed when Site options is empty */}
        {!options.sites.length && <MenuItem disabled value={-1} children="No item" />}

        {options.sites.map(({ uid, name }) => (
          <MenuItem key={uid} value={uid} children={name} />
        ))}
      </CustomFormSelect>

      <CustomFormSelect
        fullWidth
        required
        label="Select Camera *"
        control={control}
        name="camera"
        rules={{ required: true }}
      >
        {/* Will be displayed when Checkpoint Type options is empty */}
        {!availableCameraOptions.length && <MenuItem disabled value={-1} children="No item" />}

        {availableCameraOptions.map( cameraOption => (
          <MenuItem key={cameraOption.camera.uid} value={cameraOption.camera.uid}>
            { cameraOption.camera.name }
            {
              cameraOption.referenced && (
                <StarsRoundedIcon fontSize="medium"/>
              )
            }
          </MenuItem>
        ))}
      </CustomFormSelect>

      <Typography fontSize={18} lineHeight="22px" fontWeight={700} mt={3}>
        Checklist
      </Typography>

      <Box mt={1.5}>
        {checklistItems.map((checklistItem, index) => {
          return (
            <ChecklistCard
              key={checklistItem}
              index={index}
              checklistItem={checklistItem}
              mt={0.5}
              onDelete={handleDeleteCheckpoint}
              onMove={handleMoveChecklistItem}
            />
          );
        })}
      </Box>
      
      <AddChecklistItem />
    </Box>
  );
};

export default CheckpointForm;
