import { FC } from "react";

// components
import { Box, BoxProps, IconButton, Typography } from "@mui/material";
import IconSvg from "../../components/IconSvg";

export type CheckpointCardProps = BoxProps & {
  index: number,
  checkpoint: { uid: string, name: string },
  onDelete: (no: string) => void,
  onMove: (no: string, direction: "up" | "down") => void,
}

const CheckpointCard: FC<CheckpointCardProps> = ({
  index,
  checkpoint,
  onDelete,
  onMove,
  ...props
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      bgcolor="#1E1E2E"
      border="1px solid #4D4D4D"
      borderRadius={1}
      py={1}
      pl={2}
      pr={1}
      {...props}
    >
      <Box display="flex" alignItems="center" mr={2}>
        <Typography mr={2}>{index + 1}</Typography>
        <Typography>{checkpoint.name}</Typography>
      </Box>

      <Box flexBasis={110} display="flex" justifyContent="end">
        <IconButton sx={{ mr: 1 }} onClick={() => onMove(checkpoint.uid, "up")}>
          <IconSvg icon="arrow-up" width={15} />
        </IconButton>
        <IconButton sx={{ mr: 1 }} onClick={() => onMove(checkpoint.uid, "down")}>
          <IconSvg icon="arrow-down" width={15} />
        </IconButton>
        <IconButton onClick={() => onDelete(checkpoint.uid)}>
          <IconSvg icon="trash" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CheckpointCard;
