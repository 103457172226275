import { Drawer, Toolbar, Divider, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { AnyAbility } from '@casl/ability';
import { useAbility } from '@casl/react';
import { Link } from 'react-router-dom';
import * as R from "ramda";

import { AbilityContext } from '../../Context/Ability';

type MainMenuPropType = {
  routerConfig: Application.RouterConfig
};

const canViewList = ( ability: AnyAbility ) => ( route: Application.Route ) => {
  const action = route.action ? route.action: "read";
  const rules = ability.rulesFor( action, route.name );
  return (
    ability.can( action, route.name )
    && ( action === "read" && !rules[ 0 ].conditions )
  );
};

const MainMenu = ( props: MainMenuPropType ) => {
  const { routerConfig } = props;
  const ability = useAbility( AbilityContext );
  const filteredMenuItems = Object.values( routerConfig )
    .filter(
      route => (
        route.name == 'main' ||
        canViewList( ability )( route )
      )
    )
    .filter(
      R.has( 'label' )
    );
  
  return (
    <Drawer
      sx={{
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { boxSizing: 'border-box' },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <Divider />
      <List>
        {
          filteredMenuItems.map( ( route, index ) => (
            <ListItem key={index} disablePadding>
              <ListItemButton>
                <Link to={route.link ? route.link : route.path}>
                  <ListItemText primary={route.label} />
                </Link>
              </ListItemButton>
            </ListItem>
          ) )
        }
      </List>
    </Drawer>
  )
}

export default MainMenu;