import { AsyncSubject } from 'rxjs';
import { concatMap } from 'rxjs/operators';

const getMedia = ( socketInterface: any, streamAddress: string, streamParams: string | { [ key: string ]: string } ) => {
  const mimeCodec = 'video/mp4; codecs="avc1.4D401F"';
  if ( 'MediaSource' in window && MediaSource.isTypeSupported( mimeCodec ) ) {
    const mediaSource = new MediaSource();
    mediaSource.addEventListener( 'sourceopen', () => {
      const streamParamsObj = typeof streamParams === 'string' ? JSON.parse( streamParams ) : streamParams;
      const videoStream = socketInterface.loadVideoStream( streamAddress, streamParamsObj );
      const sourceBuffer = mediaSource.addSourceBuffer( mimeCodec );
      videoStream
        .pipe(
          concatMap( ( data: any ) => {
            const completeSubject = new AsyncSubject();
            sourceBuffer.appendBuffer( data );
            const onUpdateEnd = ( _: Event ) => {
              sourceBuffer.removeEventListener( 'updateend', onUpdateEnd );
              completeSubject.next( {} );
              completeSubject.complete();
            };
            sourceBuffer.addEventListener( 'updateend', onUpdateEnd );
            return completeSubject;
          } ),
        )
        .subscribe();
    } );
    return mediaSource;
  }
  return null;
};

export default getMedia;