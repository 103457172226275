import React, { useState, useEffect, ImgHTMLAttributes } from 'react';


type ImageProp = ImgHTMLAttributes<HTMLImageElement> & {
  apiClient: API.Client,
  src: string,
};

export default ( prop: ImageProp ) => {
  const { apiClient, src, ...rest } = prop;
  const [ imageUrl, setImageUrl ] = useState( '' );

  useEffect( () => {
    async function loadImage() {
      const imageBlob = await apiClient.Image?.get( src );
      if ( imageBlob ) {
        setImageUrl( URL.createObjectURL( imageBlob ) );
      }
    }
    if ( src !== '' ) {
      loadImage();
    }
  }, [ src ] )

  if ( imageUrl === '' ) {
    return (
      <></>
    )
  }
  return (
    <img src={ imageUrl } { ...rest } />
  )
};