import { getScheduleApi } from "./getSchedule";

// utils
import { recurrenceUtil } from "../../../utils/recurrence";

// types
import { ScheduleFormData } from "../types";

export const addScheduleApi = ( apiInstance: API.Client ) => async (data: ScheduleFormData ) => {
  const uid = await apiInstance.Entity.collection( 'schedule' ).add({
    scheduleRule: recurrenceUtil.toString(data.scheduleRule),
    scheduleTime: data.scheduleTime,
    routeRefId: data.route,
    assignOperatorRefIds: data.operators,
  });

  return await getScheduleApi( apiInstance )(uid);
};
