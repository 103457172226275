import { Button, ButtonProps, Typography } from "@mui/material"

interface Props extends ButtonProps {
    btnText?: string;
    btnType?: string;
    datatestid?: string;
    onClick?: () => void;
}

const CustomBtn = ({ btnText, btnType, datatestid, onClick, ...props }: Props) => {
  return (
    <Button
      type={'submit'}
      data-testid={datatestid || 'btn'}
      onClick={onClick}
      {...props}
      sx={{
        border: theme => ( `${btnType === 'outlined' && `1px solid ${theme.palette.primary.main}`}` ),
        minWidth: 94,
        height: 35,
        ...props.sx
      }}
    >
      <Typography variant='body2'>{btnText}</Typography>
    </Button>
  );
};

export default CustomBtn;




