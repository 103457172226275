import { FC } from "react";
import { Box, BoxProps, Typography, TypographyProps } from "@mui/material";

type ModalHeaderProps = BoxProps & {
  typographyProps?: Omit<TypographyProps, "children">;
};

const ModalHeader: FC<ModalHeaderProps> = (
  { typographyProps, children, ...props }
) => (
  <Box px={ 3 } py={ 2.5 } borderBottom="1px solid rgba(255, 255, 255, 0.2)" { ...props }>
    <Typography fontSize={ 20 } lineHeight="24px" fontWeight={ 700 } { ...typographyProps }>
      { children }
    </Typography>
  </Box>
);

export default ModalHeader;
