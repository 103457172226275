import * as R from 'ramda';
import { ScheduleDisplayData } from '../types';

export default (
  apiInstance: API.Client
) => async (
  scheduleDisplayDataList: ScheduleDisplayData[],
) => {
  const notPatrolledScheduleList = scheduleDisplayDataList.filter( scheduleData => (
    !scheduleData.patrolled
  ) );

  if ( R.isEmpty( notPatrolledScheduleList ) ) {
    return scheduleDisplayDataList;
  }

  const patrolQuery = {
    $or: notPatrolledScheduleList.map( scheduleData => ( {
      scheduleUid: scheduleData.scheduleDetail.uid,
      scheduledDateTime: `${ scheduleData.time }`
    } ) )
  };

  const patrolResultPromise = apiInstance.Entity
    .collection( 'patrol' )
    .getQuery( patrolQuery )
    .then( ( result: DataModel.Patrol[] ) => Array.from(
      new Set( 
        result.map( patrol => `${ patrol.scheduleUid }:${ patrol.scheduledDateTime }` ) 
      )
    ) );

  const patrolCacheQuery = {
    $or: notPatrolledScheduleList.map( scheduleData => (
      {
        scheduleUid: scheduleData.scheduleDetail.uid,
        scheduleTimestamp: scheduleData.time
      }
    ) )
  };

  const patrolCacheResultPromise = apiInstance.Entity
    .collection( 'patrolCache' )
    .getQuery( patrolCacheQuery )
    .then( ( result: DataModel.PatrolCache[] ) => Array.from(
      new Set( 
        result.map( record => `${ record.scheduleUid }:${ record.scheduleTimestamp }` ) 
      )
    ) );

  const [ patrol, patrolCache ] = await Promise.all(
    [ patrolResultPromise, patrolCacheResultPromise ]
  );

  return scheduleDisplayDataList.map( scheduleData => {
    if ( scheduleData.patrolled ) {
      return { ...scheduleData, isPatrolling: false };
    }
    const scheduleKey = `${ scheduleData.scheduleDetail.uid }:${ scheduleData.time }`;
    return {
      ...scheduleData,
      patrolled: R.includes( scheduleKey )( patrol ),
      isPatrolling: R.includes( scheduleKey )( patrolCache )
    };
  } );
}