import { FC, useEffect, useMemo } from "react";

// components
import { Box, IconButton, MenuItem, Select, TableCell } from "@mui/material";
import TablePaper from "../../components/Table/Paper";
import Table from "../../components/Table";
import TablePagination from "../../components/Table/Pagination";
import IconSvg from "../../components/IconSvg";

// hooks
import useTableData from "../../hooks/useTableData";
import { Can } from "../../Context/Ability";

// types
import { Table as TableType } from "../../types/components/Table";
import { UserDisplayData } from "./types";

export interface UsersTableProps {
  loading: boolean;
  userId?: string;
  data: UserDisplayData[];
  roles: { key: string, name: string }[];
  onEdit: (data: UserDisplayData) => void;
  onEditRole: (data: { uid: string; role: string }) => void;
}

const UsersTable: FC<UsersTableProps> = ({ loading, roles, userId, data, onEdit, onEditRole }) => {
  const rolesMap = new Map(
    roles.map( entry => (
      [ entry.key, entry.name ]
    ) )
  );
  
  const columns = useMemo<TableType.Column<UserDisplayData>[]>(() => {
    return [
      {
        id: "no",
        sortable: true,
        header: "NO",
        accessor: "no",
        width: 80,
      },
      {
        id: "name",
        sortable: true,
        header: "NAME",
        accessor: "displayName",
        width: "20%",
      },
      {
        id: "email",
        sortable: true,
        header: "EMAIL",
        accessor: "username",
        width: "40%",
      },
      {
        id: "role",
        header: "ROLE",
        renderBodyCell: (row, cellProps) => (
          <TableCell {...cellProps} sx={{ py: 0 }}>
            <Can not I="update" a="user" field="role">
              { rolesMap.get( row.role ) || "" }
            </Can>
            <Can I="update" a="user" field="role">
              <Select
                  value={row.role}
                  size="small"
                  color="primary"
                  variant="standard"
                  sx={{ mt: 1 }}
                  onChange={(event) => onEditRole({ uid: row.uid, role: event.target.value })}
                  style={{ minWidth: 120 }}
                >
                  {/* Will be displayed when there are no options left */}
                  {!roles.length && <MenuItem disabled value={-1} children="No item" />}

                  {roles.map((role) => (
                    <MenuItem key={role.key} value={role.key} children={role.name} />
                  ))}
              </Select>
            </Can>
          </TableCell>
        ),
        width: "40%",
      },
      {
        id: "aciton",
        header: "ACTION",
        renderBodyCell: (row, cellProps) => (
          <TableCell {...cellProps} sx={{ py: 0 }}>
            <Can I="update" a="user" field="role">
              <IconButton onClick={() => onEdit(row)}>
                <IconSvg icon="pen" />
              </IconButton>
            </Can>
          </TableCell>
        ),
        width: 180,
        tableCellProps: { align: "right" },
      },
    ];
  }, [userId, roles, onEdit, onEditRole]);

  const { displayData, order, orderBy, setData, handleSort, paginationProps } =
    useTableData<UserDisplayData>({
      sort: { order: "asc", orderBy: "no" },
      columns,
    });

  useEffect(() => {
    setData(data);
  }, [data, setData]);

  return (
    <TablePaper>
      <Box flexGrow={1}>
        <Table
          loading={loading}
          dataKey="uid"
          order={order}
          orderBy={orderBy}
          columns={columns}
          data={displayData}
          onSort={handleSort}
        />
      </Box>

      <TablePagination {...paginationProps} />
    </TablePaper>
  );
};

export default UsersTable;
