import { FC, useState, useEffect } from "react";
import { useForm } from "react-hook-form";

// components
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { ReportRounded } from "@mui/icons-material";
import IconSvg from "../../components/IconSvg";
import Modal, { ModalProps } from "../../components/ModalDialogue";
import ModalHeader from "../../components/ModalDialogue/Header";
import ModalFooter from "../../components/ModalDialogue/Footer";
import ModalBody from "../../components/ModalDialogue/Body";
import CheckpointForm from "./CheckpointForm";
import { Can } from "../../Context/Ability";

// types
import { CheckpointDisplayData, CheckpointFormData, CheckpointOptionsType } from "./types";

export interface EditCheckpointModalProps extends Omit<ModalProps, "children"> {
  checkpoint: CheckpointDisplayData;
  getReferences: ( uid: string ) => Promise<{ entities: string[] }>;
  isOptionLoaded: boolean;
  options: CheckpointOptionsType;
  onDelete: (uid: string) => void;
  onEdit: (checkpoint: CheckpointFormData & { uid: string }) => void;
}

const EditCheckpointModal: FC<EditCheckpointModalProps> = ({
  open,
  checkpoint,
  getReferences,
  isOptionLoaded,
  options,
  onDelete,
  onEdit,
  onClose,
  ...props
}) => {
  const form = useForm<CheckpointFormData>({
    mode: "onBlur",
    defaultValues: { name: "", description: "", site: "", camera: "", checklistItems: [] },
  });
  const { setValue, handleSubmit } = form;

  const handleClose = () => onClose?.({}, "backdropClick");

  const handleDelete = () => {
    onDelete( checkpoint.uid );
    handleClose();
  };

  const handleEdit = handleSubmit((data) => {
    onEdit({ ...data, uid: checkpoint.uid });
    handleClose();
  });

  const [ hasReferences, setHasReferences ] = useState( false );
  useEffect( () => {
    async function updateHasReferences () {
      const refResult = await getReferences( checkpoint.uid );
      setHasReferences( refResult && refResult.entities.length > 0 );
    }
    updateHasReferences();
  }, [ getReferences, checkpoint.uid ] )
  
  useEffect(() => {
    // Because form value will be set to [Select] component,
    //   so when options are empty but [Select] has value, MUI will throw warning
    // We have to wait until options were loaded
    // Note: when options are empty (Ex: api didn't return data), MUI will throw warning
    if (isOptionLoaded) {
      setValue("name", checkpoint.name);
      setValue("description", checkpoint.description);
      checkpoint.site && setValue("site", checkpoint.site.uid);
      checkpoint.camera && setValue("camera", checkpoint.camera.uid);
      setValue("checklistItems", checkpoint.checklistItems);
    }
    
  }, [isOptionLoaded, setValue, checkpoint]);
  
  return (
    <Modal open={open} boxProps={{ height: 800 }} onClose={onClose} {...props}>
      <>
      <ModalHeader children="Edit Checkpoint" />

      <ModalBody>
        <CheckpointForm form={form} options={options} />
      </ModalBody>

      <ModalFooter>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            <Can I="delete" a="checkpoint">
              <Button variant="text" color="error" disabled={ hasReferences } onClick={handleDelete}>
                <IconSvg icon="trash" style={{ marginRight: 10 }} />
                Delete
              </Button>
              {
                hasReferences && (
                  <Tooltip title="Entry is referenced by other Entities." placement="top">
                    <IconButton aria-label="info">
                      <ReportRounded />
                    </IconButton>
                  </Tooltip>
                )
              }
            </Can>
          </Box>

          <Box>
            <Button variant="text" color="secondary" children="Cancel" onClick={handleClose} />
            <Button variant="contained" sx={{ ml: 2 }} children="Update" onClick={handleEdit} />
          </Box>
        </Box>
      </ModalFooter>
      </>
    </Modal>
  );
};

export default EditCheckpointModal;
