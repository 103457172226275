import { FC } from "react";

import { Box, BoxProps, Modal as MuiModal, ModalProps as MuiModalProps } from "@mui/material";

export type ModalProps = Omit<MuiModalProps, "childred"> & {
  boxProps?: BoxProps;
  children: BoxProps["children"];
};

const Modal: FC<ModalProps> = (
  { boxProps, children, ...props }
) => (
  <MuiModal { ...props }>
    <Box
      width={ 600 }
      maxWidth="calc(100% - 50px)"
      maxHeight="calc(100% - 50px)"
      { ...boxProps }
      position="absolute"
      top="50%"
      left="50%"
      display="flex"
      flexDirection="column"
      bgcolor="#27293C"
      borderRadius={ 1 }
      sx={ { transform: "translate(-50%, -50%)", outline: "none" } }
    >
      { children }
    </Box>
  </MuiModal>
);

export default Modal;
