import { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// components
import { Box, Typography } from "@mui/material";
import Modal, { ModalProps } from "../../components/ModalDialogue";
import ModalHeader from "../../components/ModalDialogue/Header";
import ModalBody from "../../components/ModalDialogue/Body";
import CheckpointsTable from "./CheckpointsTable";

// apis
import { getLogApi } from "./action";

dayjs.extend( utc );

export type LogDetailModalProps = Omit<ModalProps, "children"> & {
  apiClientInstance: API.Client
};

const LogDetailModal: FC<LogDetailModalProps> = ( props ) => {
  const { apiClientInstance, ...rest } = props;
  const { uid } = useParams();

  const [mounted, setMounted] = useState(false);
  const [data, setData] = useState<any>();

  // Set flag mounted
  useEffect(() => {
    setMounted( true );
  }, []);

  // get log detail from server
  useEffect(() => {
    // Call api only 1 time
    if ( uid && mounted ) {
      getLogApi( apiClientInstance )( uid ).then((res) => {
        console.log( res );

        res && res.n && setData( res.n );
      });
    }
  }, [uid, mounted]);
  

  return (
    <Modal
      boxProps={{ height: "100%", width: "100%", maxWidth: "100%", maxHeight: "100%" }}
      {...rest}
    >
      <>
        <ModalHeader>
          <Link to="/log">&lt;</Link>  Log Detail
        </ModalHeader>
        <ModalBody>
          <Typography fontSize={18} lineHeight="22px" fontWeight={700} children="Patrol Details" />

          <Box display="flex" mt={2.5}>
            <Box flexBasis={180}>
              <Typography fontSize={12} lineHeight="14px" fontWeight={700} children="Site" />
              <Typography mt={0.5} fontSize={16} lineHeight="19px">
                { data?.site?.name || "-" }
              </Typography>
            </Box>

            <Box flexBasis={180}>
              <Typography fontSize={12} lineHeight="14px" fontWeight={700} children="Route" />
              <Typography mt={0.5} fontSize={16} lineHeight="19px">
                { data?.route?.name || "-"}
              </Typography>
            </Box>

            <Box flexBasis={180}>
              <Typography fontSize={12} lineHeight="14px" fontWeight={700} children="Date & Time" />
              <Typography
                mt={0.5}
                fontSize={16}
                lineHeight="19px"
                children={ dayjs.unix( data?.patrolStartDateTime ).local().format("D MMM YYYY, hh:mm A") || "-" }
              />
            </Box>

            <Box>
              <Typography fontSize={12} lineHeight="14px" fontWeight={700} children="Operator" />
              <Typography mt={0.5} fontSize={16} lineHeight="19px">
                { data?.operator?.name || "-"}
              </Typography>
            </Box>
          </Box>

          <Typography fontSize={18} lineHeight="22px" fontWeight={700} mt={7}>
            Checkpoints & Checklist
          </Typography>

          <Box mt={3}>
            <CheckpointsTable
              loading={ false }
              apiClientInstance={apiClientInstance}
              checkpoints={ data?.checkpoints || []}
            />
          </Box>
        </ModalBody>
      </>
    </Modal>
  );
};

export default LogDetailModal;
