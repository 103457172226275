import { Box, TextField } from "@mui/material"
import { styled } from '@mui/material/styles';

export default function Textarea(props: any) {
    return (
      <Box
          sx={{
              '& > :not(style)': { width: '100%' },
          }}
      >
        <CssTextField variant="outlined"
            inputProps={{ "data-testid": props.datatestid || "text-area" }}
            multiline
            minRows={4}
            maxRows={4}
            {...props}
        />
      </Box>
    );
}


const options = {
    shouldForwardProp: (prop: any) => prop !== 'fontColor',
};
const CssTextField = styled(
    TextField,
    options,
)(({ fontColor, bordercolor }: any) => ({
    input: {
        color: `${fontColor} !important`,
    },
    "& .MuiOutlinedInput-root": {
        color: fontColor,
        padding: 8,
        border: `1px solid ${bordercolor}`
    },
}));
