import io from 'socket.io-client';
import { fromEvent } from 'rxjs';
import {
  mergeMap, first, tap,
} from 'rxjs/operators';

const webSocketService = ( config: any ) => {
  let cacheStreamId: any = null;
  const ioInstance = io( config.baseAddress, {
    path: `${ config.path }/socket.io/`,
    transports: [ 'websocket' ],
  } );
  ioInstance.on( 'connect_error', () => {
    setTimeout( () => {
      ioInstance.connect();
    }, 1000 );
  } );
  return {
    loadVideoStream: ( address: string, params: string ) => {
      const stream = fromEvent( ioInstance as any, 'playResponse' )
        .pipe(
          first(),
          mergeMap( streamId => {
            cacheStreamId = streamId;
            return fromEvent( ioInstance as any, `data:${ streamId }` )
              .pipe(
                tap( () => ioInstance.send( { function: 'ack', streamId } ) )
              );
          } ),
        );
      ioInstance.on( 'connect', () => {
        ioInstance.send( { function: 'play', address, params } );
      } );
      return stream;
    },
    close: () => {
      if ( ioInstance.connected ) {
        ioInstance.send( { function: 'stop', streamId: cacheStreamId } );
        cacheStreamId = null;
      }
    },
  };
};

export default webSocketService;