import { getCheckpointApi } from "./getCheckpoint";

// types
import { CheckpointFormData } from "../types";

export const editCheckpointApi = ( apiInstance: API.Client ) => async (data: CheckpointFormData & { uid: string }) => {
  await apiInstance.Entity.collection( 'checkpoint' ).update(data.uid, {
    name: data.name,
    description: data.description,
    siteRefId: data.site,
    cameraRefId: data.camera,
    checklistItems: data.checklistItems,
  } as unknown as JSON );

  return getCheckpointApi( apiInstance )(data.uid);
};
