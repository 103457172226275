import { AccountUserClientType } from "../AccountUser";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend( utc );

export type SummaryClient = {
  get: () => Promise<Response>,
}

const createClient = ( config: Application.ConfigType ) => (
  accountUserClient: AccountUserClientType
) => {
  const {
    domain, service
  } = config.api as {
    baseUrl: string, domain: string, service: string 
  };
  const {
    baseUrl
  } = config.summary as { baseUrl: string };

  const getSendOptions = ( tokenObj: { type: string, token: string }, postForm = false ) => {
    const initialOption = {
      headers: {
        authorization: `Bearer ${ tokenObj.token }`,
      },
      withCredentials: true,
    };

    return postForm
      ? { 
        ...initialOption,
        headers: {
          ...initialOption.headers,
          'Content-Type': 'application/json'
        }
      }
      : initialOption;
  };

  return {
    get: () => (
      accountUserClient.getToken( domain, service )
        .then( token => (
          fetch( `${baseUrl}/report/summary?isoTimestamp=${ dayjs().local().toISOString() }`, {
            ...getSendOptions( token ),
            method: 'GET'
          } as RequestInit )
        ) )
    ),
  };
};

export default createClient;