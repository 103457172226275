import React from 'react';
import { Outlet } from 'react-router';
import { Box, Container, Toolbar } from '@mui/material';

import Header from '../Header';

import MainMenu from '../MainMenu';

import { AccountUserClientType } from '../../clients/AccountUser';

type LayoutProp = {
  routerConfig: Application.RouterConfig,
  accountUserClient: AccountUserClientType,
  onLogout: () => void,
}

const Layout = ( props: LayoutProp ) => {
  const { routerConfig, accountUserClient } = props;
  return (
    <Box sx={{ display: 'flex', alignItems: 'stretch', minHeight: '100vh' }}>
      <Header accountUserClient={accountUserClient} onLogout={ props.onLogout } />
      <MainMenu routerConfig={ routerConfig }/>
      <Box 
        component="main"
        sx={ {
          backgroundColor: 'background.paper',
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto' 
        } }
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ p: 2, ml: 'auto', mr: 'auto' }}>
          <Outlet />
        </Container>
      </Box>
    </Box>
  )
  
}

export default Layout;