export const getPatrolDetails = ( apiInstance: API.Client ) => (
  async ( uid: string ) => {
    const patrol = await apiInstance.Entity.collection( 'patrol' ).get( uid );
    const site = await apiInstance.Entity.collection( 'site' ).get( patrol.site.uid );
    return {
      ...patrol,
      site,
    };
  }
);
