import { FC, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";

import { Box, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import StarsRoundedIcon from '@mui/icons-material/StarsRounded';
import CustomFormSelect from "../../components/CustomFormSelect";
import CustomTextField from "../../components/CustomTextField";
import CheckpointCard from "./CheckpointCard";

import { RouteFormDataType, RouteOptionsType } from "./types";


type RouteFormProps = {
  form: UseFormReturn<RouteFormDataType>,
  options: RouteOptionsType,
}

const RouteForm: FC<RouteFormProps> = ({ form, options }) => {
  const { control, setValue, watch } = form;
  const checkpoints = watch( "checkpoints" );
  const siteId = watch("site");

  const [checkpointOptions, setCheckpointOptions] = useState<{ checkpoint: DataModel.Checkpoint, referenced: boolean }[]>([]);

  const handleAddCheckpoint = ({ target }: SelectChangeEvent<number>) => {
    const addingCheckpoint = options.checkpoints.find(
      ( checkpointOption ) => checkpointOption.checkpoint.uid === target.value
    )?.checkpoint;

    if (addingCheckpoint) {
      // add new selected checkpoint
      setValue("checkpoints", [...checkpoints, addingCheckpoint]);
    }
  };

  const handleDeleteCheckpoint = (uid: string) => {
    setValue(
      "checkpoints",
      checkpoints.filter(( checkpoint ) => checkpoint.uid !== uid)
    );
  };

  const handleMoveCheckpoint = (uid: string, direction: "up" | "down") => {
    const index = checkpoints.findIndex((checkpoint) => checkpoint.uid === uid);
    if (index < 0) return;

    // checkpoint is at the top of the list
    if (index === 0 && direction === "up") return;
    // checkpoint is at the end of the list
    if (index === checkpoints.length - 1 && direction === "down") return;

    const moveToIndex = direction === "up" ? index - 1 : index + 1;

    // clone [checkpoints]
    const updatingCheckpoints = [...checkpoints];

    // swap items to move
    const swap = updatingCheckpoints[index];
    updatingCheckpoints[index] = updatingCheckpoints[moveToIndex];
    updatingCheckpoints[moveToIndex] = swap;
    
    setValue("checkpoints", updatingCheckpoints);
  };
  
  useEffect(() => {
    const availableCheckpoints = siteId
      ? options.checkpoints.filter( checkpointOption => checkpointOption.checkpoint.siteRefId === siteId )
      : [ ]
    
    setCheckpointOptions( availableCheckpoints );
  }, [ options.checkpoints, siteId ]);

  return (
    <Box>
      <Typography fontSize={18} lineHeight="22px" fontWeight={700} children="Details" />

      <CustomTextField
        fullWidth
        required
        label="Name"
        sx={{ mt: 2 }}
        control={control}
        name="name"
        rules={{ required: true }}
      />

      <CustomFormSelect
        fullWidth
        required
        label="Site *"
        control={control}
        name="site"
        rules={{ required: true }}
      >
        {/* Will be displayed when Site options is empty */}
        {!options.sites.length && <MenuItem disabled value={-1} children="No item" />}

        {options.sites.map( ( { uid, name } ) => (
          <MenuItem key={uid} value={uid} children={ name } />
        ))}
      </CustomFormSelect>

      <CustomFormSelect
        fullWidth
        required
        label="Route Type *"
        control={control}
        name="routeType"
        rules={{ required: true }}
      >
        {/* Will be displayed when Route Type options is empty */}
        {!options.routeTypes.length && <MenuItem disabled value={-1} children="No item" />}

        {options.routeTypes.map(({ key, displayName }) => (
          <MenuItem key={key} value={key} children={displayName} />
        ))}
      </CustomFormSelect>

      <Typography fontSize={18} lineHeight="22px" fontWeight={700} mt={3}>
        Checkpoint Selection
      </Typography>

      <Box mt={1.5}>
        {checkpoints.map((checkpoint, index) => {
          return (
            <CheckpointCard
              key={checkpoint.uid}
              index={index}
              checkpoint={checkpoint}
              mt={0.5}
              onDelete={handleDeleteCheckpoint}
              onMove={handleMoveCheckpoint}
            />
          );
        })}
      </Box>

      <Select
        required
        fullWidth
        value={0}
        size="medium"
        color="primary"
        sx={{ mt: 1 }}
        onChange={handleAddCheckpoint}
        displayEmpty
      >
        {/* 
            "+ Add checkpoint" always display in [Select], so we have to use prop [value] is "label".
            But in options list, we don't want to show "+ Add checkpoint", so we will hide it
             */}
        <MenuItem value={0} sx={{ display: "none" }} children="+ Add checkpoint" />

        {/* Will be displayed when there are no options left */}
        {!checkpointOptions.length && <MenuItem disabled value={-1} children="No item" />}

        {checkpointOptions.map((checkpointOption) => (
          <MenuItem key={checkpointOption.checkpoint.uid} value={checkpointOption.checkpoint.uid}>
            { checkpointOption.checkpoint.name }
            {
              checkpointOption.referenced && (
                <StarsRoundedIcon fontSize="medium"/>
              )
            }
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default RouteForm;
