import { FC } from "react";
import { useForm } from "react-hook-form";


// components
import { Button } from "@mui/material";
import Modal, { ModalProps } from "../../components/ModalDialogue";
import ModalHeader from "../../components/ModalDialogue/Header";
import ModalFooter from "../../components/ModalDialogue/Footer";
import ModalBody from "../../components/ModalDialogue/Body";
import ScheduleForm from "./ScheduleForm";

// types
import { ScheduleFormData, ScheduleOptions } from "./types";

export type AddScheduleModalProps = Omit<ModalProps, "children"> & {
  options: ScheduleOptions,
  onAdd: (route: ScheduleFormData) => void,
}

const AddScheduleModal: FC<AddScheduleModalProps> = ({ options, onAdd, onClose, ...props }) => {
  const form = useForm<ScheduleFormData>({
    mode: "onBlur",
    defaultValues: { scheduleRule: [], scheduleTime: "", route: "", operators: [] },
  });
  const { handleSubmit } = form;

  const handleClose = () => onClose?.({}, "backdropClick");

  const handleAdd = handleSubmit((data) => {
    onAdd(data);
    handleClose();
  });

  return (
    <Modal boxProps={{ height: 800 }} onClose={onClose} {...props}>
      <>
        <ModalHeader children="New Schedule" />

        <ModalBody>
          <ScheduleForm form={form} options={options} />
        </ModalBody>

        <ModalFooter>
          <Button variant="text" color="secondary" children="Cancel" onClick={handleClose} />
          <Button variant="contained" sx={{ ml: 2 }} children="Create" onClick={handleAdd} />
        </ModalFooter>
      </>
    </Modal>
  );
};

export default AddScheduleModal;
