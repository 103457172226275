import axios from "axios";

export type AccountUserClientType = {
  getLoginLink: ( currentAddress: string ) => Promise<string>,
  getChangePasswordLink: ( currentAddress: string ) => Promise<string>,
  getToken: ( domain: string, service: string ) => Promise<{ type: string, token: string }>,
  getRoleOptions: () => Promise<{ key: string, name: string }[]>
  get: () => Promise<JSON | null>,
  add: ( entity: JSON ) => Promise<string>,
  update: ( uid: string, entity: JSON ) => Promise<any>,
  delete: ( uid: string ) => Promise<any>,
  logout: () => Promise<any> 
}

const createClient = ( config: Application.ConfigType ) => {
  const user = config.user as Application.ConfigType;
  const { baseUrl, domain, service } = user as { baseUrl: string, domain: string, service: string };
  const providerUrlClient = axios.create( {
    baseURL: baseUrl,
  } );

  const getToken = ( domain: string, service: string ) => providerUrlClient.post( '/token', {
    domain, service
  }, { withCredentials: true } ).then( response => response.data );

  const clientInstance = {
    getLoginLink: ( currentAddress: string ) => providerUrlClient.get( '/account/login/link', {
      params: {
        redirect_to: currentAddress,
      },
    } ).then( response => response.data.redirect ),
    getChangePasswordLink: ( currentAddress: string ) => getToken( domain, service ).then( ( { token } ) => (
      providerUrlClient.get( `/user/password/change/link?redirect_to=${ currentAddress }`, {
          headers: { Authorization: `Bearer ${ token }` },
          withCredentials: true
        } )
      ) ).then( response => response.data ),
    getToken,
    get: () => providerUrlClient.get( '/token/user', { withCredentials: true } )
      .then( response => (
        response.status !== 200
        ? null
        : response.data
      ) ).catch( () => null ),
    getRoleOptions: () => getToken( domain, service ).then( ( { token } ) => (
      providerUrlClient.get( '/role', {
        headers: { Authorization: `Bearer ${ token }` },
        withCredentials: true
      } )
    ) ).then( response => response.data ),
    add: ( entity: JSON ) => getToken( domain, service ).then( ( { token } ) => (
      providerUrlClient.post( '/user', entity, {
        headers: { Authorization: `Bearer ${ token }` },
        withCredentials: true
      } )
    ) ).then( response => response.data ),
    update: ( uid: string, entity: JSON ) => getToken( domain, service ).then( ( { token } ) => (
      providerUrlClient.patch( `/user/${ uid }`, entity, {
        headers: { Authorization: `Bearer ${ token }` },
        withCredentials: true
      } )
    ) ),
    delete: ( uid: string ) => getToken( domain, service ).then( ( { token } ) => (
      providerUrlClient.delete( `/user/${ uid }`, {
        headers: { Authorization: `Bearer ${ token }` },
        withCredentials: true
      } )
    ) ),
    logout: () => providerUrlClient.delete( '/account/logout', {
      withCredentials: true,
    } ),
  }
  return clientInstance as AccountUserClientType;
};

export default createClient;