import * as R from 'ramda';

import { getUserApi } from "./getUser";

// types
import { UserFormData } from "../types";
import { AccountUserClientType } from "../../../clients/AccountUser";

export const editUserApi = (
  accountUserClient: AccountUserClientType,
  apiInstance: API.Client
) => async (data: UserFormData & { uid: string }) => {
  const formToUpdate = R.dissoc( 'uid', data );
  await accountUserClient.update( data.uid, formToUpdate as unknown as JSON );
  await apiInstance.Entity
    .collection( 'user' )
    .update(data.uid, formToUpdate);

  return getUserApi( apiInstance )( data.uid );
};
