import { Fragment } from 'react';
import {
  TableRow,
  TableCell,
  TableCellProps,
  SxProps,
} from '@mui/material';

import { Table } from "../../../types/components/Table";


type RowPropType<TData> = {
  columns: Table.Column<TData>[],
  row: TData,
  isLessBorder ?: boolean,
  isLastRow: boolean,
  dataKey: keyof TData,
  dataAccessor: (row: TData, id: keyof TData, defaultValue?: string) => any
}

type ColumnPropType<TData> = {
  column: Table.Column<TData>,
  row: TData,
  isLessBorder ?: boolean,
  isLastRow ?: boolean,
  dataAccessor: (row: TData, id: keyof TData, defaultValue?: string) => any
};

const Column = <TData extends Record<string, any>>( props: ColumnPropType<TData> ) => {
  const { column, row, isLessBorder, isLastRow, dataAccessor } = props;
  const cellProps: TableCellProps = {
    ...column.tableCellProps,
    ...column.tableBodyCellProps,
    sx: {
      borderBottom: isLessBorder && isLastRow ? "none" : undefined,
      ...column.tableCellProps?.sx,
      ...column.tableBodyCellProps?.sx,
    } as SxProps,
  };
  
  if ( column.accessor ) {
    return (
      <TableCell key={column.id} {...cellProps}>
        { dataAccessor(row, column.id) }
      </TableCell>
    );
  }
  if ( column.renderBodyCell ) {
    return (
      <Fragment key={ column.id }>
        { column.renderBodyCell( row, cellProps ) }
      </Fragment>
    );
  }
  return <Fragment key={ column.id } />;
}

const Row =  <TData extends Record<string, any>>( props: RowPropType<TData> ) => {
  const { columns, dataKey, ...rest } = props;
  return (
    <TableRow key={ rest.row[ dataKey ] }>
      { columns.map( ( column, idx ) => (
        <Column key={ idx } column={ column } { ...rest } />
      ) ) }
    </TableRow>
  )
}

export default Row;