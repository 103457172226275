import { FC, useEffect, useMemo } from "react";
import { Download } from "@mui/icons-material";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// components
import { Box, IconButton, TableCell } from "@mui/material";
import TablePaper from "../../components/Table/Paper";
import Table from "../../components/Table";
import TablePagination from "../../components/Table/Pagination";

// hooks
import useTableData from "../../hooks/useTableData";

// types
import { Table as TableType } from '../../types/components/Table';
import { useNavigate } from "react-router-dom";

dayjs.extend( utc );

export interface ReportTableProps {
  loading: boolean;
  data: DataModel.Patrol[];
  onClickDownload: ( uid: string ) => void;
}

type PatrolDisplayData = DataModel.Patrol & {
  no: number,
};

const ReportTable: FC<ReportTableProps> = ({ loading, data, onClickDownload }) => {
  const navigate = useNavigate();

  const columns = useMemo<TableType.Column<PatrolDisplayData>[]>(() => {
    return [
      {
        id: "no",
        sortable: true,
        header: "NO",
        accessor: "no",
        width: 80,
      },
      {
        id: "time",
        sortable: true,
        header: "DATE & TIME",
        accessor: ( row ) => dayjs.unix( row.patrolStartDateTime ).format( 'DD/MM/YYYY hh:mm A' ),
        sortAccessor: (row) => row.patrolStartDateTime,
        width: "15%",
      },
      {
        id: "site",
        sortable: true,
        header: "SITE",
        accessor: (row) => row.site.name,
        width: "15%",
      },
      {
        id: "route",
        sortable: true,
        header: "ROUTE",
        accessor: (row) => row.route.name,
        width: "15%",
      },
      {
        id: "operator",
        sortable: true,
        header: "OPERATOR",
        accessor: (row) => row.operator.name,
        width: "15%",
      },
      {
        id: "action",
        header: "ACTION",
        renderBodyCell: (row, cellProps) => (
          <TableCell {...cellProps} sx={{ py: 0 }}>
            <IconButton onClick={() => onClickDownload( row.uid )}>
              <Download />
            </IconButton>
          </TableCell>
        ),
        width: 150,
        tableCellProps: { align: "right" },
      },
    ];
  }, [navigate]);

  const { displayData, order, orderBy, setData, handleSort, paginationProps } =
    useTableData<any>({
      sort: { order: "desc", orderBy: "time" },
      columns,
    });

  useEffect(() => {
    const entries = data.map( ( entry, idx ) => ( { 
      no: idx + 1,
      ...entry
    } ) );
    setData( entries );
  }, [ data, setData ]);

  return (
    <TablePaper>
      <Box flexGrow={1}>
        <Table
          loading={loading}
          dataKey="uid"
          order={order}
          orderBy={orderBy as string}
          columns={columns}
          data={displayData}
          onSort={handleSort}
        />
      </Box>

      <TablePagination {...paginationProps} />
    </TablePaper>
  );
};

export default ReportTable;
