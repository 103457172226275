import { PaletteOptions } from "@mui/material";

export const themeColors = {
  primary: '#4d7df2',
  secondary: '#4d7df2',
  success: '#00d41a',
  info: '#00d0bd',
  warning: '#ffb70f',
  error: '#e53935',
  textPrimary: '#ffffff',
  textSecondary: '#717275',
  divider: 'rgba(255,255,255,0.12)',
  icon: '#717275',
  bgPaper: '#27293c',
  bgDefault: '#1e1e2e',
  white: '#ffffff',
  black: '#000000',
};

export default {
  mode: 'dark',
  primary: {
    light: 'rgba(77, 125, 242, 0.4)',
    main: themeColors.primary,
    dark: 'rgba(77, 125, 242, 0.8)',
    contrastText: themeColors.white,
  },
  secondary: {
    light: themeColors.secondary,
    main: themeColors.secondary,
    dark: themeColors.secondary,
    contrastText: themeColors.white,
  },
  text: {
    primary: themeColors.textPrimary,
    secondary: themeColors.textSecondary,
    link: themeColors.primary,
  },
  divider: themeColors.divider,
  icon: themeColors.icon,
  background: {
    paper: themeColors.bgPaper,
    default: themeColors.bgDefault,
  }
} as unknown as PaletteOptions;