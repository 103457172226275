import { useState, useEffect } from "react";
import dayjs from "dayjs";

export default () => {
  const [ currentTime, setCurrentTime ] = useState( dayjs().unix() );
  useEffect( () => {
    const initialTime = dayjs();
    const roundUpTime = initialTime.endOf( 'seconds' );
    let interval: any = null;
    setTimeout(() => {
      interval = setInterval( () => {
        const pTime = dayjs();
        setCurrentTime( pTime.unix() );
      }, 1000 );
    }, roundUpTime.millisecond() - initialTime.millisecond() );

    return () => { if ( interval !== null ) { clearInterval( interval ); } }
  }, [ ] );
  return currentTime;
}