import { FC, useEffect, useMemo } from "react";

// components
import { Box, IconButton, TableCell } from "@mui/material";
import TablePaper from "../../components/Table/Paper";
import Table from "../../components/Table";
import TablePagination from "../../components/Table/Pagination";
import IconSvg from "../../components/IconSvg";
import { Can } from "../../Context/Ability";

// hooks
import useTableData from "../../hooks/useTableData";

import { extendTimeWithDate } from "../../utils/dateTime";

// types
import { Table as TableType } from "../../types/components/Table";
import { ScheduleDisplayData, ScheduleOptions } from "./types";
import { recurrenceUtil } from "../../utils/recurrence";

export interface SchedulesTableProps {
  loading: boolean;
  data: ScheduleDisplayData[];
  options: ScheduleOptions;
  onEdit: (data: ScheduleDisplayData) => void;
}

const SchedulesTable: FC<SchedulesTableProps> = ({ loading, data, options, onEdit }) => {
  const columns = useMemo<TableType.Column<ScheduleDisplayData>[]>(() => {
    return [
      {
        id: "no",
        sortable: true,
        header: "NO",
        accessor: "no",
        width: 80,
      },
      {
        id: "selectedDays",
        sortable: true,
        header: "SELECTED DAY",
        accessor: (row) => {
          const weekDays = recurrenceUtil.parseToList(row.scheduleRule);
          if (!weekDays.length || weekDays.length === 7) return "Daily";

          return weekDays.map((weekDay) => recurrenceUtil.daysShortText[ weekDay.weekday ] ).join(", ");
        },
        sortAccessor: (row: ScheduleDisplayData) => {
          const weekDays = recurrenceUtil.parseToList(row.scheduleRule);
          // selected "Daily"
          if (!weekDays.length || weekDays.length === 7) return 7;
          return weekDays.length;
        },
        width: "20%",
      },
      {
        id: "scheduleTime",
        sortable: true,
        header: "TIME",
        accessor: (row) => `${ extendTimeWithDate( row.scheduleTime ).format("hh:mm A") }`,
        sortAccessor: (row: ScheduleDisplayData ) => `${ extendTimeWithDate( row.scheduleTime ).format("HH:mm:ss")}`,
        width: "20%",
      },
      {
        id: "route",
        sortable: true,
        header: "ROUTE",
        accessor: (row) => row.route.name,
        width: "20%",
      },
      {
        id: "operator",
        sortable: true,
        header: "OPERATOR",
        accessor: (row) => {
          if (row.operators.length === options.operators.length) return "All Operators";
          return row.operators.map(({ displayName }) => displayName).join(", ");
        },
        sortAccessor: (row: ScheduleDisplayData) => row.operators.length,
        width: "40%",
      },
      {
        id: "aciton",
        header: "ACTION",
        renderBodyCell: (row, cellProps) => (
          <TableCell {...cellProps} sx={{ py: 0 }}>
            <Can I="update" a="schedule">
              <IconButton onClick={() => onEdit(row)}>
                <IconSvg icon="pen" />
              </IconButton>
            </Can>
          </TableCell>
        ),
        width: 150,
        tableCellProps: { align: "right" },
      },
    ];
  }, [options, onEdit]);

  const { displayData, order, orderBy, setData, handleSort, paginationProps } =
    useTableData<ScheduleDisplayData>({
      sort: { order: "asc", orderBy: "no" },
      columns,
    });

  useEffect(() => {
    setData(data);
  }, [data, setData]);

  return (
    <TablePaper>
      <Box flexGrow={1}>
        <Table
          loading={loading}
          dataKey="uid"
          order={order}
          orderBy={orderBy}
          columns={columns}
          data={displayData}
          onSort={handleSort}
        />
      </Box>

      <TablePagination {...paginationProps} />
    </TablePaper>
  );
};

export default SchedulesTable;
