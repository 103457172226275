// types
import { ScheduleDisplayData } from "../types";

export const getScheduleDetail = ( apiInstance: API.Client ) => async (schedule: DataModel.Schedule) => {
  // get related route
  const routeIncludePromise = apiInstance.Entity.collection( 'route' ).get(schedule.routeRefId);
  // get related operators
  const operatorsIncludePromise = schedule.assignOperatorRefIds.map(async (assignOperatorRefId) =>
    apiInstance.Entity.collection( 'user' ).get(assignOperatorRefId)
  );

  const [route, ...operators] = await Promise.all([
    routeIncludePromise,
    ...operatorsIncludePromise,
  ]);

  return { ...schedule, route, operators, no: 0 } as ScheduleDisplayData;
};

export const getScheduleApi = ( apiInstance: API.Client ) => async (uid: string): Promise<ScheduleDisplayData | null> => {
  const schedule = await apiInstance.Entity.collection( 'schedule' ).get(uid);
  if (!schedule) return null;

  return getScheduleDetail( apiInstance )(schedule);
};
