import { RouteOptionsType } from "../types";
export const getRouteOptionsApi = async ( apiInstance: API.Client ) => {
  const sitePromise = apiInstance.Entity.collection( 'site' ).getAll();
  const routeTypePromise = apiInstance.Map?.collection( 'routeType' ).getAll();
  const checkpoints = await apiInstance.Entity.collection( 'checkpoint' ).getAll();

  const checkpointOptionPromise = Promise.all(
    checkpoints.map(
      ( checkpoint: DataModel.Checkpoint ) => apiInstance.Entity
        .collection( 'checkpoint' )
        .getReferences( checkpoint.uid )
        .then( ( refResult: { entities: string[] } ) => ( {
          checkpoint,
          referenced: refResult.entities.includes( 'route' )
        } ) )
    )
  )

  const [
    sites,
    routeTypes,
    checkpointOptions,
  ] = await Promise.all([sitePromise, routeTypePromise, checkpointOptionPromise]);

  return {
    sites,
    routeTypes: routeTypes as unknown as DataModel.RouteType[], 
    checkpoints: checkpointOptions 
  } as RouteOptionsType;
};
