import {
  TableHead,
  TableRow,
} from "@mui/material";

import HeaderColumn from './Column';

import { Table } from '../../../types/components/Table';

export type TableHeaderProps<TData> = {
  columns: Table.Column<TData>[],
  isLessBorder ?: boolean,
  order ?: Table.Order,
  orderBy ?: string,
  onSort ?: ( id: string ) => void,
}

const TableHeader = <TData extends Record<string, any>>( props: TableHeaderProps<TData> ) => {
  const { columns, ...rest } = props;
  
  return (
    <TableHead sx={{ bgcolor: "#222335" }}>
      <TableRow>
      { 
        columns.map( ( column, index ) => (
          <HeaderColumn key={index} column={ column } { ...rest } />
        ) ) 
      } 
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;