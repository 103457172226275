import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import Section from './Section';
import TickIcon from '../../ReportIcons/TickIcon';
import TimeIcon from '../../ReportIcons/TimeIcon';

dayjs.extend( utc );

const checkpointStyle = StyleSheet.create( {
  table: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    textAlign: 'center',
  },
  row:{
    display: 'flex',
    flexDirection: 'row',
  },
  cell: {
    borderWidth: 1,
    display: 'flex',
    textAlign: 'center',
    flexWrap: 'wrap',
    padding: 5,
  }
} );


const CheckpointSection = ( props: any ) => {
  const { checkpoints, operator } = props;
  return (
    <Section title={ <Text style={{ padding: 2 }}>SCANNED CHECKPOINT(S)</Text> } >
      <View style={ checkpointStyle.table }>
        <View style={ checkpointStyle.row }>
          <Text style={ [ checkpointStyle.cell, checkpointStyle.header, { width: 30 } ] }>Seq</Text>
          <Text style={ [ checkpointStyle.cell, checkpointStyle.header, { width: 140 } ] }>Checkpoint</Text>
          <Text style={ [ checkpointStyle.cell, checkpointStyle.header, { width: 250 } ] }>Checklist</Text>
          <Text style={ [ checkpointStyle.cell, checkpointStyle.header, { width: 170 } ] }>Scanned Time</Text>
          <Text style={ [ checkpointStyle.cell, checkpointStyle.header, { width: 140 } ] }>Notes</Text>
          <Text style={ [ checkpointStyle.cell, checkpointStyle.header, { width: 140 } ] }>Operator</Text>
          <Text style={ [ checkpointStyle.cell, checkpointStyle.header, { width: 90 } ] }>Image</Text>
        </View>
        {
          checkpoints.map( ( checkpoint: any, index: number ) => (
            <View key={ index } style={ checkpointStyle.table }>
              <View style={ checkpointStyle.row }>
                <Text style={ [ checkpointStyle.cell, { width: 30, borderBottom: 0 } ] }>{ index + 1 }</Text>
                <Text style={ [ checkpointStyle.cell, { width: 140 } ] }>{ checkpoint.name }</Text>
                <View style={ [ checkpointStyle.cell, { width: 250, flexDirection: 'column' } ] }>
                {
                  checkpoint.checklist.map( ( item: any, idx: number ) => (
                    <View key={idx} style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingBottom: 4 }}>
                      <View style={{ flexBasis: '10%', flexShrink: 0, paddingTop: 4 }}>
                        { item.checked ? (
                          <TickIcon style={ { width: 8 } } />
                        ) : (
                          <TimeIcon style={ { width: 7 } } />
                        ) }
                      </View>
                      <View style={{ flexBasis: '90%', flexShrink: 0 }}>
                        <Text style={ {  textAlign: 'left' } }>
                          { item.description }
                        </Text>
                      </View>
                    </View>
                  ) )
                }
                </View>
                <Text style={ [ checkpointStyle.cell, { width: 170 } ] }>
                  {
                    dayjs.unix( checkpoint.timestamp )
                      .local().format( 'dddd, MMM D, YYYY h:mm:ss A' )
                  }
                </Text>
                <Text style={ [ checkpointStyle.cell, { width: 140 } ] }>{ checkpoint.notes }</Text>
                <Text style={ [ checkpointStyle.cell, { width: 140 } ] }>{ operator.name }</Text>
                <View style={ [ checkpointStyle.cell, { width: 90 } ] }>
                  {
                    checkpoint.imageUrl && (
                      <Image src={ checkpoint.imageUrl } style={{ width: '100%' }} />
                    )
                  }
                </View>
              </View>
              <View style={ checkpointStyle.row }>
                <View style={ [ checkpointStyle.cell, { width: 30, borderTop: 0 } ] }></View>
                <View style={ [ checkpointStyle.cell, { width: 930 } ] }>
                {
                  checkpoint.incidents.length > 0 && (
                    <View style={ [ checkpointStyle.table, { width: '80%', margin: 'auto' } ] }>
                      <View style={ checkpointStyle.row }>
                        <Text style={ [ checkpointStyle.cell, checkpointStyle.header, { width: '50%' } ] }>Incident</Text>
                        <Text style={ [ checkpointStyle.cell, checkpointStyle.header, { width: '50%' } ] }>Notes</Text>
                      </View>
                      {
                        checkpoint.incidents.map( ( incident: any, idx: number ) => (
                          <View key={ `incident-${ idx }` } style={ checkpointStyle.row }>
                            <Text style={ [ checkpointStyle.cell, { width: '50%' } ] }>{ incident.incidentType.displayName }</Text>
                            <Text style={ [ checkpointStyle.cell, { width: '50%' } ] }>{ incident.comments }</Text>
                          </View>
                        ) )
                      }
                    </View>
                  )
                }
                </View>
              </View>
            </View>
          ) )
        }
      </View>
    </Section>
  );
};
  
export default CheckpointSection;