import { PathValue, Path, useController, UseControllerProps } from "react-hook-form";

// components
import {
  FormControl,
  FormControlProps,
  InputLabel,
  InputLabelProps,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";

export interface CustomFormSelectProps<FormDataType>
  extends UseControllerProps<FormDataType>,
    Omit<SelectProps, "name" | "defaultValue"> {
  fromControlProps?: FormControlProps;
  inputLabelProps?: InputLabelProps;
  mapChangeValue?: (
    value: string | PathValue<FormDataType, Path<FormDataType>>
  ) => PathValue<FormDataType, Path<FormDataType>>;

  // multiple props
  options?: any[];
  getOptionValue?: (data: any) => any;
  getOptionDisplay?: (data: any) => any;
  selectedAllText?: string;
}

const CustomFormSelect = <FormDataType extends Record<string, any>>({
  control,
  name,
  rules,
  defaultValue,

  // Select props
  multiple,
  label,
  size = "medium",
  fromControlProps,
  inputLabelProps,
  mapChangeValue,

  // multiple props
  options,
  getOptionValue,
  getOptionDisplay,
  selectedAllText = "All",
  ...props
}: CustomFormSelectProps<FormDataType>) => {
  const { field, formState } = useController({ control, name, rules, defaultValue });

  let sxInputLabel = { ...inputLabelProps?.sx };

  if (!field.value.length && size === "medium") {
    sxInputLabel = {
      ...sxInputLabel,
      "&:not(.Mui-focused)": { transform: "translate(14px, 12px) scale(1)" },
    };
  }

  const handleChange = (event: SelectChangeEvent<PathValue<FormDataType, Path<FormDataType>>>) => {
    // single-selection
    if (!options || !getOptionValue || !multiple) return field.onChange(event);

    // multiple-selection
    const inputValues = event.target.value as string[];
    let newValues: string[] = [...inputValues];

    // When all item was checked/unchecked
    if (inputValues[inputValues.length - 1] === "all") {
      if (field.value.length > 0 && field.value.length === options.length) {
        // When all options were selected, remove All
        newValues = [];
      } else {
        // when no option was selected, select All
        newValues = [...options.map((option) => getOptionValue?.(option))];
      }
    }

    // update value to [react-hook-form]
    field.onChange({ ...event, target: { ...event.target, value: newValues } });
  };

  const renderMultipleValue = (value: string[]) => {
    if (!options || !multiple || !getOptionValue || !getOptionDisplay) return value;

    // handle multiple select
    if (field.value.length > 0 && field.value.length === options.length) {
      // When all options selected
      return selectedAllText;
    } else {
      // When a part of options slected
      return `${value.length} selected`;
    }
  };

  return (
    <FormControl fullWidth sx={{ mt: 3 }} {...fromControlProps}>
      <InputLabel
        {...inputLabelProps}
        // [sxInputLabel] can't be override by [props], so we put it below {...props}
        sx={sxInputLabel}
      >
        {label}
      </InputLabel>
      <Select
        multiple={multiple}
        label={label}
        size={size}
        color="primary"
        renderValue={multiple ? renderMultipleValue : undefined}
        {...props}
        // form control
        error={!!formState.errors[name]}
        {...field}
        onChange={handleChange}
      />
    </FormControl>
  );
};

export default CustomFormSelect;
