import { View, Text, StyleSheet } from '@react-pdf/renderer';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Duration from 'dayjs/plugin/duration';

dayjs.extend( utc );
dayjs.extend( Duration );

import Section from './Section';

const timeStyle = StyleSheet.create( {
  row: {
    flexDirection: 'row'
  },
  cell: {
    width: '33%',
    padding: 2,
    textAlign: 'left',
  }
} );

const TimeSection = ( props: any ) => (
  <Section title={ 
    <View style={ timeStyle.row }>
      <Text style={ timeStyle.cell }>REPORT START TIME</Text>
      <Text style={ timeStyle.cell }>REPORT SUBMIT TIME</Text>
      <Text style={ timeStyle.cell }>TOTAL SPEND TIME</Text>
    </View>
  }>
    <View style={ timeStyle.row }>
      <Text style={ timeStyle.cell }>
        { dayjs.unix( props.patrolStartDateTime ).local().format( 'DD/MM/YYYY hh:mm A' ) }
      </Text>
      <Text style={ timeStyle.cell }>
        { dayjs.unix( props.patrolEndDateTime ).local().format( 'DD/MM/YYYY hh:mm A' ) }
      </Text>
      <Text style={ timeStyle.cell }>
        {
          dayjs
            .duration(
              dayjs.unix( props.patrolEndDateTime )
                .diff( dayjs.unix( props.patrolStartDateTime ) )
            )
            .format( 'H[H] m[m]' )
        }
      </Text>
    </View>
  </Section>
);

export default TimeSection;