import { FC, useEffect } from "react";
import { UseFormReturn } from "react-hook-form";

// components
import { Box, MenuItem, Typography, Checkbox, ListItemText } from "@mui/material";
import CustomFormSelect from "../../components/CustomFormSelect";
import CustomTextField from "../../components/CustomTextField";

// types
import { UserFormData, UserOptions } from "./types";

export type UserFormProps = {
  form: UseFormReturn<UserFormData>,
  options: UserOptions,
};

const UserForm: FC<UserFormProps> = ({ form, options }) => {
  const { control, watch, setValue } = form;
  const sites = watch("siteIds");
  const role = watch("role");

  // remove all selected sites when the role is not 'customer'
  useEffect( () => {
    if ( role === 'customer' ) return;
    setValue( 'siteIds', [] );
  }, [ role ] );

  return (
    <Box>
      <Typography fontSize={18} lineHeight="22px" fontWeight={700} children="Identity" />

      <CustomTextField
        fullWidth
        required
        label="Name"
        sx={{ mt: 2 }}
        control={control}
        name="displayName"
        rules={{ required: true }}
      />

      <CustomTextField
        fullWidth
        required
        label="Email"
        sx={{ mt: 2 }}
        control={control}
        name="username"
        rules={{ required: true }}
      />

      <Typography fontSize={18} lineHeight="22px" fontWeight={700} mt={4}>
        Access Rights
      </Typography>

      <CustomFormSelect
        fullWidth
        required
        label="Role *"
        control={control}
        name="role"
        rules={{ required: true }}
      >
        {/* Will be displayed when Site options is empty */}
        {!options.roles.length && <MenuItem disabled value={-1} children="No item" />}

        {options.roles.map((role) => (
          <MenuItem key={role.key} value={role.key} children={ role.name } />
        ))}
      </CustomFormSelect>

      { role === 'customer' && (
        <>
          <Typography fontSize={18} lineHeight="22px" fontWeight={700} mt={4}>
            Sites
          </Typography>

          <CustomFormSelect
            fullWidth
            multiple
            label="Sites"
            control={control}
            name="siteIds"
            options={options.sites}
            getOptionValue={( option: DataModel.Site ) => option.uid }
            getOptionDisplay={( option: DataModel.Site ) => option.name }
          >
          <MenuItem value="all">
            <Checkbox checked={sites?.length === options.sites.length} />
            <ListItemText primary="All" />
          </MenuItem>

            {options.sites.map((site) => (
              <MenuItem
                key={site.uid}
                value={site.uid}
                sx={{ "&.Mui-selected": { bgcolor: "transparent" } }}
              >
                <Checkbox checked={ sites?.includes(site.uid) } />
                <ListItemText primary={ site.name } />
              </MenuItem>
            ))}
          </CustomFormSelect>
        </>
        )}
      
    </Box>
  );
};

export default UserForm;
