const icons = {
  tick: require("./tick.svg"),
  time: require("./time.svg"),
  info: require("./info.svg"),
  pen: require("./pen.svg"),
  trash: require("./trash.svg"),
  "arrow-up": require("./arrow-up.svg"),
  "arrow-down": require("./arrow-down.svg"),
};

export default icons;
