// api instance
import { getScheduleDetail } from "./getSchedule";

// types
import { ScheduleDisplayData } from "../types";

export const getSchedulesApi = async ( apiInstance: API.Client ): Promise<ScheduleDisplayData[]> => {
  const schedules = await apiInstance.Entity.collection( 'schedule' ).getAll();

  const scheduleResultsIncludePromise = schedules.map(async (schedule: DataModel.Schedule ) =>
    getScheduleDetail( apiInstance )( schedule )
  );

  const schedulesResult = await Promise.all(scheduleResultsIncludePromise);

  return schedulesResult.map((schedule, index) => ({ ...schedule, no: index + 1 }));
};
