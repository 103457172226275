import { FC, useEffect, useState, useMemo } from "react";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Box, Button, TableCell } from "@mui/material";

import addPatrolCacheEntry from "./action/addPatrolCacheEntry";
import deletePatrolCacheEntry from "./action/deletePatrolCacheEntry";

// components
import TablePaper from "../../components/Table/Paper";
import Table from "../../components/Table";
import TablePagination from "../../components/Table/Pagination";

// hooks
import useTableData from "../../hooks/useTableData";

// types
import { Table as TableType } from "../../types/components/Table";
import { ScheduleDisplayData } from "./types";

// audio
import alarmMp3 from "../../assets/audio/alarm.mp3";

dayjs.extend( utc );

const alarmAudio = new Audio( alarmMp3 );

export type SchedulesTableDataProps = ScheduleDisplayData & {
  currentTime: number
};
export interface SchedulesTableProps {
  loading: boolean;
  data: SchedulesTableDataProps[];
  user: DataModel.User;
  loginTime: number | null;
  apiInstance: API.Client;
}

const SchedulesTable: FC<SchedulesTableProps> = ( {
  loading,
  data,
  user,
  loginTime,
  apiInstance,
} ) => {
  const [ isPlayingAlarm, setPlayingAlarm ] = useState( false );
  const [ clickedStartIds, setClickedStartIds ] = useState<Set<string>>( new Set() );
  useEffect( () => {
    const newClickedStartIds = new Set(
      data.filter( row => !row.isPatrolling )
        .map( row => row.uid )
    );
    setClickedStartIds( newClickedStartIds );
  }, [ data ] );


  const checkVisibleStart = (row: SchedulesTableDataProps) => {
    const isAssignee = row.assignedOperatorRefIds.includes( user.uid );
    return isAssignee && !row.patrolled;
  }

  const checkEnableStart = (row: SchedulesTableDataProps) => {
    const isSameAndAfterTime = !dayjs.unix( row.currentTime ).isBefore( dayjs.unix( row.time ) );
    const isNotPatrolling = !row.isPatrolling || !clickedStartIds.has( row.uid );
    return isSameAndAfterTime && isNotPatrolling;
  }

  const columns = useMemo<TableType.Column<ScheduleDisplayData & { "no": number, "currentTime": number }>[]>(() => {
    return [
      {
        id: "no",
        sortable: true,
        header: "NO",
        accessor: "no",
        width: 80,
      },
      {
        id: "scheduleTime",
        sortable: true,
        header: "TIME",
        accessor: (row) => ( dayjs.unix( row.time ).format("hh:mm A") ),
        sortAccessor: ( row: ScheduleDisplayData ) => ( row.time ),
        width: "20%",
      },
      {
        id: "route",
        sortable: true,
        header: "ROUTE",
        accessor: (row) => row.route.name,
        width: "20%",
      },
      {
        id: "checkpoint",
        header: "CHECKPOINTS",
        accessor: ( row ) => row.route.checkpointRefIds.length,
        width: "20%",
      },
      {
        id: "aciton",
        header: "ACTIONS",
        renderBodyCell: (row, cellProps) => {
          return (
            <TableCell {...cellProps} sx={{ py: 0 }}>
            {
              ( 
                checkVisibleStart(row)
              )
              && (
                <Button 
                  variant="contained" 
                  disabled={ !checkEnableStart(row) }
                  onClick={ () => {
                    setClickedStartIds( new Set( clickedStartIds.add( row.uid ) ) );
                    const windowReference = window.open(
                      `/patrol/${ row.uid }/${ row.time }`,
                      '_blank',
                      `${ 'toolbar=0,menubar=0,width=' }${ window.screen.availWidth },height=${ window.screen.availHeight }`,
                    );
                    addPatrolCacheEntry( apiInstance )( {
                      scheduleUid: row.uid,
                      scheduleTimestamp: Number( row.time ),
                      started: true
                    } ).then( cacheUid => {
                      const windowStatusChange = setInterval( async () => {
                        if ( windowReference && windowReference.closed ) {
                          await deletePatrolCacheEntry( apiInstance )( cacheUid );
                          clearInterval( windowStatusChange );
                        }
                      }, 500 );
                    } );
                  } }
                >
                  Start
                </Button>
              )
            }
            </TableCell>
          );
        },
        width: 150,
        tableCellProps: { align: "right" },
      },
    ];
  }, [ user ]);

  const { displayData, order, orderBy, setData, handleSort, paginationProps } =
    useTableData<ScheduleDisplayData & { "no": number, "currentTime": number }>({
      sort: { order: "asc", orderBy: "no" },
      columns,
    });

  useEffect(() => {
    const indexedData = data.map( ( entry, idx ) => ( {
      ...entry,
      no: idx + 1
    } ) );

    setData( indexedData );
  }, [data, setData]);

  useEffect( () => {
    if ( !data?.length || !loginTime ) return;

    // all schedules which have schduleTime is after loginTime
    const scheduleAfterLoginTime = data.filter( ({ time }) => time > loginTime );

    const enabledScheduleSet = new Set<string>();
    scheduleAfterLoginTime.forEach( row => {
      // After click start, [checkEnableStart] will still be [true] until the polling get new data
      // by using [clickedStartIds], we can check that immediately
      if (!clickedStartIds.has( row.uid ) &&  checkVisibleStart( row ) && checkEnableStart( row ) ) {
        enabledScheduleSet.add( row.uid );
      }
    } );

    setPlayingAlarm( !!enabledScheduleSet.size );
  }, [ data, loginTime, clickedStartIds ] );

  // play alarm
  useEffect( () => {
    if ( isPlayingAlarm ) {
      alarmAudio.loop = true;
      alarmAudio.play();
    } else {
      alarmAudio.pause();
      alarmAudio.currentTime = 0;
    }
  }, [ isPlayingAlarm ] );

  return (
    <TablePaper>
      <Box flexGrow={1}>
        <Table
          loading={loading}
          dataKey="uid"
          order={order}
          orderBy={orderBy}
          columns={columns}
          data={displayData}
          onSort={handleSort}
        />
      </Box>

      <TablePagination {...paginationProps} />
    </TablePaper>
  );
};

export default SchedulesTable;
