import { FC } from "react";

// components
import { Paper, PaperProps } from "@mui/material";

const TablePaper: FC<PaperProps> = (props) => {
  return (
    <Paper
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        bgcolor: "#2A2A3C",
        backgroundImage: "none",
        borderRadius: 1,
        overflow: "hidden",
        boxShadow: "none",
        ...props.sx,
      }}
      {...props}
    />
  );
};

export default TablePaper;
