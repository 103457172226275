import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend( utc );

export const extendTimeWithDate = ( time: string ) => (
  dayjs(
    `${ dayjs().startOf( 'year' ).format( 'YYYY-MM-DD' ) }T${ time }`
  )
);

export const convertTimeStringToLocal = (
  { timeString, isAlwaysAfter }: { timeString: string ,isAlwaysAfter?: boolean }
) => {
  // Ex: 2022-08-13
  const localDate = dayjs().format( 'YYYY-MM-DD' );
  // Ex: timeString = '00:00:00+08:00'
  // ->  2022-08-13T00:00:00+8:00
  const dateTimeString = `${ localDate }T${ timeString }`;

  if ( isAlwaysAfter ) {
    // after convert to local timezone, reset time can be before current local time 
    // Ex: local timezone: GTM+9
    //     localTime:       "2022-08-13T12:00:00+9:00"
    //     serverResetTime: "2022-08-13T00:00:00+8:00"
    // =>  localResetTime:  "2022-08-13T01:00:00+9:00"
    //     expected:        "2022-08-14T01:00:00+9:00"
    if ( dayjs( dateTimeString ).unix() <= dayjs().unix() ) {
      return dayjs( dateTimeString ).add( 1, "day" );
    }
  }
  
  return dayjs( dateTimeString );
}