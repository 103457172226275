import { useEffect, useState } from "react";
import dayjs from "dayjs";

// components
import { Box, Button, Typography } from "@mui/material";
import SchedulesTable from "./SchedulesTable";
import AddScheduleModal from "./AddScheduleModal";
import EditScheduleModal from "./EditScheduleModal";
import { Can } from "../../Context/Ability";

// apis
import {
  getSchedulesApi,
  addScheduleApi,
  editScheduleApi,
  deleteScheduleApi,
  getScheduleOptionsApi,
  getScheduleReferences,
} from "./action";

// types
import { ScheduleFormData, ScheduleOptions, ScheduleDisplayData } from "./types";

type ManageSchedulesProps = {
  apiClientInstance: API.Client,
};

const ManageSchedules = ( props: ManageSchedulesProps ) => {
  const { apiClientInstance } = props;
  const [openAddModal, setOpenAddModal] = useState(false);

  const [mounted, setMounted] = useState( false );
  const [loading, setLoading] = useState( true );
  const [isOptionLoaded, setOptionLoaded] = useState(false);

  const [data, setData] = useState<ScheduleDisplayData[]>([]);
  const [editingData, setEditingData] = useState<ScheduleDisplayData | undefined>();
  const [options, setOptions] = useState<ScheduleOptions>({
    days: [],
    routes: [],
    operators: [],
  });

  // Ex: 15:30 -> 3:30 PM
  const formatUpdateTime = (timeValue: string) => {
    // Ex: ["15", "30"]
    const [hours, minutes] = timeValue.split(":");
    return dayjs()
      .set("hours", Number(hours))
      .set("minutes", Number(minutes))
      .set("seconds", 0)
      .format("HH:mm:ssZ");
  };

  const handleAdd = (schedule: ScheduleFormData) => {
    addScheduleApi( apiClientInstance )(
      { ...schedule, scheduleTime: formatUpdateTime(schedule.scheduleTime) }
    ).then(
      ( res ) => {
        res && setData([...data, { ...res, no: data.length + 1 }]);
      }
    );
  };

  const handleEdit = (schedule: ScheduleFormData & { uid: string }) => {
    editScheduleApi( apiClientInstance )({ ...schedule, scheduleTime: formatUpdateTime(schedule.scheduleTime) }).then(
      (res) => {
        res && setData(data.map((item) => (item.uid !== res.uid ? item : { ...res, no: item.no })));
      }
    );
  };

  const handleDelete = (uid: string) => {
    deleteScheduleApi( apiClientInstance )(uid).then(() => {
      setData(
        data.filter((item) => item.uid !== uid).map((item, index) => ({ ...item, no: index + 1 }))
      );
    });
  };

  // Set flag mounted
  useEffect(() => {
    setMounted(true);
  }, []);

  // get schedules from server
  useEffect(() => {
    // Call api only 1 time
    if (mounted) {
      // Table data need [schedules] and [options] to render data
      // using [Promise.all] to make sure [schedules] and [options] will be loaded in same time
      Promise.all([getSchedulesApi( apiClientInstance ), getScheduleOptionsApi( apiClientInstance )]).then(([schedules, options]) => {
        setData(schedules);
        setOptions(options);

        setLoading(false);
        setOptionLoaded(true);
      });
    }
  }, [mounted, setData]);

  return (
    <Box display="flex" flexDirection="column" height="100%" p={4} bgcolor="#1E1E2E">
      <Box display="flex" justifyContent="space-between" alignItems="end" mb={1.875}>
        <Typography fontSize={35} fontWeight={700} children="Manage Schedules" />
        <Can I="create" a="schedule">
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ color: "#FFFFFF" }}
            onClick={() => setOpenAddModal(true)}
          >
            + Add Schedule
          </Button>
        </Can>
      </Box>

      <SchedulesTable
        loading={loading}
        data={data}
        options={options}
        onEdit={(data) => setEditingData(data)}
      />

      {openAddModal && (
        <AddScheduleModal
          open={openAddModal}
          options={options}
          onAdd={handleAdd}
          onClose={() => setOpenAddModal(false)}
        />
      )}

      {!!editingData && (
        <EditScheduleModal
          open={true}
          isOptionLoaded={isOptionLoaded}
          options={options}
          schedule={editingData}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onClose={() => setEditingData(undefined)}
          getReferences={ getScheduleReferences( apiClientInstance ) }
        />
      )}
    </Box>
  );
};

export default ManageSchedules;
