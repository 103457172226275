import ndjsonStream from 'can-ndjson-stream';

import { ApiClientType } from '../AdminApi';

const readCollection = async ( response: Response ) => {
  const reader = ndjsonStream( response.body ).getReader();
  const resultList = [];
  let result;
  while ( !result || !result.done ) {
    result = await reader.read();
    if ( result.value ){
      resultList.push( result.value );
    }
  }
  return resultList;
};

export default ( name: string ) => ( apiClient: ApiClientType ) => ( {
  getAll: () => apiClient.get( `/map/${ name }` )
    .then( readCollection ),
  get: ( key: string ) => apiClient.get( `/map/${ name }/${ key }` )
    .then( ( response: Response ) => response.json() ),
  set: ( key: string, value: JSON ) => apiClient.put( `/map/${ name }/${ key }`, value ),
  delete: ( key: string ) => apiClient.delete( `/map/${ name }/${ key }` ),
} );