import { recurrenceUtil } from "../../../utils/recurrence";

import { ScheduleOptions } from "../types";

export const getScheduleOptionsApi = async ( apiInstance: API.Client ) => {
  // get all routes
  const routePromise = apiInstance.Entity.collection( 'route' ).getAll();
  // get all users
  const operatorsPromise = apiInstance.Entity.collection( 'user' ).getAll();

  const [routes, operators] = await Promise.all([routePromise, operatorsPromise]);

  return { routes, operators, days: recurrenceUtil.days } as ScheduleOptions;
};
