import ndjsonStream from 'can-ndjson-stream';
import { encode } from "universal-base64url";

import { ApiClientType } from '../AdminApi';

const readCollection = async ( response: Response ) => {
  const reader = ndjsonStream( response.body ).getReader();
  const resultList = [];
  let result;
  while ( !result || !result.done ) {
    result = await reader.read();
    if ( result.value ) {
      resultList.push( result.value );
    }
  }
  return resultList;
};

export default ( name: string ) => ( apiClient: ApiClientType ) => (
  {
    get: ( uid: string ) => (
      apiClient.get( `/entity/${ name }/${ uid }` )
      .then( ( response: Response ) => response.json() )
    ),
    getAll: () => (
      apiClient.get( `/entity/${ name }` )
        .then( readCollection )
    ),
    getQuery: async ( filter: JSON ) => {
      const queryString = encode( JSON.stringify( filter ) );
      const response = await apiClient.get(`/entity/${name}/where/${queryString}`);
      return readCollection( response );
    },
    getReferences: async ( uid: string ) => (
      apiClient.get( `/entity/references/${ name }/${ uid }` )
        .then( response => response.json() )
    ),
    add: ( entity: JSON ) => (
      apiClient.post( `/entity/${ name }`, entity )
        .then( ( response: Response ) => response.text() )
    ),
    update: ( uid: string, entityForm: JSON ) => (
      apiClient.patch( `/entity/${ name }/${ uid }`, entityForm )
    ),
    delete: ( uid: string ) => (
      apiClient.delete( `/entity/${ name }/${ uid }` )
    ),
  } 
);