import { FC, useEffect, useMemo } from "react";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// components
import {
  Box,
  TableCell,
  Typography,
} from "@mui/material";
import TablePaper from "../../components/Table/Paper";
import Table from "../../components/Table";
import TablePagination from "../../components/Table/Pagination";
import IconSvg from "../../components/IconSvg";
import Image from "../../components/Image";

// hooks
import useTableData from "../../hooks/useTableData";

// types
import { Table as TableType } from '../../types/components/Table';

dayjs.extend( utc );

export interface CheckpointsTableProps {
  loading: boolean;
  checkpoints: any[];
  apiClientInstance: API.Client;
}

const CheckpointsTable: FC<CheckpointsTableProps> = ({ loading, apiClientInstance, checkpoints }) => {
  const columns = useMemo<TableType.Column<any>[]>(() => {
    return [
      {
        id: "no",
        header: "NO",
        accessor: "no",
        width: 54,
        tableBodyCellProps: { sx: { verticalAlign: "top" } },
      },
      {
        id: "name",
        header: "CHECKPOINT",
        accessor: "name",
        width: 100,
        tableBodyCellProps: { sx: { verticalAlign: "top" } },
      },
      {
        id: "checklist",
        header: "CHECKLIST",
        renderBodyCell: ({ checklist: checklistItems }, cellProps) => (
          <TableCell {...cellProps}>
            <Box>
              {checklistItems.map((item: any, index: number) => (
                <Box key={index} py={0.75}>
                  <Box display="flex">
                    <Box mr={1}>
                      <IconSvg icon={item.checked ? "tick" : "time"} />
                    </Box>
                    <Typography fontSize={14} lineHeight="17px">
                      {item.description}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </TableCell>
        ),
        width: 200,
      },
      {
        id: "notes",
        header: "NOTES",
        renderBodyCell: ( { notes }, cellProps ) => (
          <TableCell {...cellProps}>
            {notes}
          </TableCell>
        ),
        width: 100,
      },
      {
        id: "snapshot",
        header: "SNAPSHOT",
        renderBodyCell: ( { image }, cellProps ) => (
          <TableCell {...cellProps}>
            {
              image
              && (
                <Image 
                  apiClient={apiClientInstance}
                  src={image}
                  width="100"
                />
              )
            }
          </TableCell>
        ),
        width: 100,
      },
      {
        id: "incidents",
        header: "INCIDENTS",
        renderBodyCell: ({ incidents }, cellProps) => (
          <TableCell {...cellProps}>
            <Box>
               { incidents.map((incident: any, idx: number) => {
                return (
                  <Box key={idx} display="flex">
                    <Typography fontSize={14} lineHeight="17px">
                      <strong>{dayjs.unix(incident.dateTime).local().format("hh:mm A")}</strong>{" "}
                      { incident.comments }
                    </Typography>
                  </Box>
                );
              }) }
            </Box>
          </TableCell>
        ),
        width: 200,
        tableBodyCellProps: { sx: { verticalAlign: "top" } },
      },
    ];
  }, []);

  const { displayData, setData, paginationProps } = useTableData<any>({ columns });

  useEffect(() => {
    const mappedCheckpoints = checkpoints.map( ( cp: any, idx: number ) => (
      {
        ...cp,
        no: idx + 1,
      }
    ) );
    setData( mappedCheckpoints );
  }, [ checkpoints, setData ]);

  return (
    <TablePaper>
      <Table
        isLessBorder
        loading={loading}
        dataKey="uid"
        columns={columns}
        data={displayData}
        tableProps={{ size: "small" }}
      />
      <TablePagination {...paginationProps} />
    </TablePaper>
  );
};

export default CheckpointsTable;
