import { FC, useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { ReportRounded } from "@mui/icons-material";
import Modal, { ModalProps } from "../../components/ModalDialogue";
import ModalHeader from "../../components/ModalDialogue/Header";
import ModalFooter from "../../components/ModalDialogue/Footer";
import ModalBody from "../../components/ModalDialogue/Body";
import IconSvg from "../../components/IconSvg";
import RouteForm from "./RouteForm";
import { Can } from "../../Context/Ability";

import { RouteDataType, RouteFormDataType, RouteOptionsType } from "./types";

export interface EditRouteModalProps extends Omit<ModalProps, "children"> {
  route: RouteDataType;
  isOptionLoaded: boolean;
  options: RouteOptionsType;
  onDelete: (uid: string) => void;
  onEdit: (
    route: RouteFormDataType & { uid: string; removedCheckpoints: DataModel.Checkpoint[] }
  ) => void;
  getReferences: ( uid: string ) => Promise<{ entities: string[] }>
}

const EditRouteModal: FC<EditRouteModalProps> = ({
  open,
  route,
  isOptionLoaded,
  options,
  onDelete,
  onEdit,
  onClose,
  getReferences,
  ...props
}) => {
  const form = useForm<RouteFormDataType>({
    mode: "onBlur",
    defaultValues: { name: "", site: "", routeType: "", checkpoints: [] },
  });
  const { setValue, handleSubmit } = form;

  const handleClose = () => onClose?.({}, "backdropClick");

  const handleDelete = () => {
    onDelete(route.uid);
    handleClose();
  };

  const handleEdit = handleSubmit((data) => {
    const orderedCheckpoints = data.checkpoints.map( ( item, index ) => ({
      ...item,
      routeCheckpointPosIdx: index,
    }));

    const removedCheckpoints = route.checkpoints.filter((checkpoint) => {
      return !data.checkpoints.find(
        (currentCheckpoint) => currentCheckpoint.uid === checkpoint.uid
      );
    });

    onEdit({ ...data, uid: route.uid, checkpoints: orderedCheckpoints, removedCheckpoints });
    handleClose();
  });

  const [ hasReferences, setHasReferences ] = useState( false );
  useEffect( () => {
    async function updateHasReferences () {
      const refResult = await getReferences( route.uid );
      setHasReferences( refResult && refResult.entities.length > 0 );
    }
    updateHasReferences();
  }, [ getReferences, route.uid ] );

  useEffect(() => {
    // Because form value will be set to [Select] component,
    //   so when options are empty but [Select] has value, MUI will throw warning
    // We have to wait until options were loaded
    // Note: when options are empty (Ex: api didn't return data), MUI will throw warning
    if ( isOptionLoaded ) {
      setValue("name", route.name);
      route.site && setValue("site", route.site.uid);
      route.routeType && setValue("routeType", route.routeType.key);
      setValue("checkpoints", route.checkpoints);
    }
  }, [isOptionLoaded, setValue, route]);

  return (
    <Modal open={open} boxProps={{ height: 800 }} onClose={onClose} {...props}>
      <>
        <ModalHeader children="Edit Route" />

        <ModalBody>
          <RouteForm form={form} options={options} />
        </ModalBody>

        <ModalFooter>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box>
              <Can I="delete" a="route">
                <Button variant="text" color="error" disabled={ hasReferences } onClick={handleDelete}>
                  <IconSvg icon="trash" style={{ marginRight: 10 }} />
                  Delete
                </Button>
                {
                  hasReferences && (
                    <Tooltip title="Entry is referenced by other Entities." placement="top">
                      <IconButton aria-label="info">
                        <ReportRounded />
                      </IconButton>
                    </Tooltip>
                  )
                }
              </Can>
            </Box>

            <Box>
              <Button variant="text" color="secondary" children="Cancel" onClick={handleClose} />
              <Button variant="contained" sx={{ ml: 2 }} children="Update" onClick={handleEdit} />
            </Box>
          </Box>
        </ModalFooter>
      </>
    </Modal>
  );
};

export default EditRouteModal;
