import React from "react";

import {
  AppBar, Toolbar, Box, IconButton, Menu, MenuItem, ListItemIcon, Typography
} from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from "react-router";

import { AccountUserClientType } from '../../clients/AccountUser';
import useUser from "../../hooks/useUser";

import headerImage from '../../assets/logo.png';

type HeaderProp = {
  accountUserClient: AccountUserClientType,
  onLogout: () => void,
};

const Header = ( props: HeaderProp ): JSX.Element => {
  const { user } = useUser( props.accountUserClient );
   const [ anchorEl, setAnchorEl ] = React.useState<HTMLElement | null>( null );
  const navigate = useNavigate();


  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleLinkToProfile = () => {
    setAnchorEl(null);
    navigate('/profile');
  };
  
  const handleLogout = async () => {
    setAnchorEl(null);
    await props.accountUserClient.logout();
    props.onLogout();
    navigate( '/logout' );
  };

  return (
    <AppBar position="fixed" color="default" sx={{ zIndex: theme => theme.zIndex.drawer + 10 }} >
      <Toolbar>
        <Box component="div" sx={{ flexGrow: 1 }}>
          <Box component="img" sx={ { height: 60 } } src={ headerImage } />
        </Box>
        <Box component="div">
          <Box display="flex" alignItems="center">
            <Typography fontWeight={700}>{user?.displayName}</Typography>

            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>
          </Box>
          <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLinkToProfile}>Profile</MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
