import React from 'react';
import { AccountUserClientType } from "../clients/AccountUser";

export default ( accountUserClient: AccountUserClientType ) => {
  const [ user, setUser ] = React.useState<DataModel.User>( {
    uid: '',
    username: '',
    displayName: '',
    role: 'customer',
    siteIds: [],
  } );
  const [ loginTime, setLoginTime ] = React.useState<number | null>( null );
  const userEffectFn = React.useCallback( async () => {
    const resultUser = await accountUserClient.get();
    if ( resultUser ) {
      const { user, loginTime } = resultUser as unknown as {
        user: DataModel.User, loginTime: number
      };
      if ( user ) {
        setUser( user );
      }
      setLoginTime( loginTime );
    }
  }, [ setUser, setLoginTime ] );
  React.useEffect( () => {
    userEffectFn();
  }, [ ] );
  
  return { user, loginTime };
}