import { getRouteApi } from "./getRoute";

import { RouteFormDataType } from "../types";

export const addRouteApi = ( apiInstance: API.Client ) => async (data: RouteFormDataType) => {
  const addedRouteId = await apiInstance.Entity.collection( 'route' ).add( {
    name: data.name,
    siteRefId: data.site,
    routeTypeKeyRef: data.routeType,
    checkpointRefIds: data.checkpoints.map( checkpoint => checkpoint.uid ),
  } as unknown as JSON );

  return getRouteApi( apiInstance )( addedRouteId );
};
