import { useEffect, useState } from "react";

// components
import { Box, Button, Typography } from "@mui/material";

// components
import UsersTable from "./UsersTable";
import AddUserModal from "./AddUserModal";
import EditUserModal from "./EditUserModal";
import { Can } from "../../Context/Ability";

// apis
import {
  getUsersApi,
  deleteUserApi,
  editUserApi,
  addUserApi,
  getUserReferences,
  getSitesApi,
} from "./action";

// types
import { UserDisplayData, UserFormData, UserOptions } from "./types";
import { AccountUserClientType } from "../../clients/AccountUser";

type ManageUsersProps = {
  accountUserClient: AccountUserClientType,
  apiClient: API.Client,
}

const ManageUsers = ( props: ManageUsersProps ) => {
  const { accountUserClient, apiClient } = props;
  const [openAddModal, setOpenAddModal] = useState(false);

  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState<UserDisplayData | null>(null);
  const [data, setData] = useState<UserDisplayData[]>([]);
  const [editingData, setEditingData] = useState<UserDisplayData | undefined>();
  const [options, setOptions] = useState<UserOptions>({ roles: [], sites: [] });

  const handleAdd = (user: UserFormData) => {
    addUserApi( accountUserClient, apiClient )(user).then((res) => {
      res && setData([...data, { ...res, no: data.length + 1 }]);
    });
  };

  const handleEdit = (user: UserFormData & { uid: string }) => {
    editUserApi( accountUserClient, apiClient )(user).then((res) => {
      res && setData(data.map((item) => (item.uid !== res.uid ? item : { ...res, no: item.no })));
    });
  };

  const handleEditRole = ({ uid, role }: { uid: string; role: string }) => {
    const editingUser = data.find((user) => user.uid === uid);
    if (!editingUser) return;

    editUserApi( accountUserClient, apiClient )({ ...editingUser, role }).then((res) => {
      res && setData(data.map((item) => (item.uid !== res.uid ? item : { ...res, no: item.no })));
    });
  };

  const handleDelete = (uid: string) => {
    deleteUserApi( accountUserClient, apiClient )(uid).then(() => {
      setData(
        data.filter((item) => item.uid !== uid).map((item, index) => ({ ...item, no: index + 1 }))
      );
    });
  };

  // Set flag mounted
  useEffect(() => {
    setMounted(true);
  }, []);

  // get routes from server
  useEffect(() => {
    // Call api only 1 time
    if (mounted) {
      Promise.all( [
        accountUserClient.get(),
        getUsersApi( apiClient ),
        accountUserClient.getRoleOptions(),
        getSitesApi( apiClient )
      ] ).then(
        ( [ loginUser, userList, roleList, sites ] ) => {
          const filteredUserList = userList.filter(
            ( iUser: UserDisplayData ) => roleList.map( role => role.key ).includes( iUser.role )
          );
          setUser( { ...loginUser, no: 0 } as UserDisplayData );
          setData( filteredUserList );
          setOptions( { roles: roleList, sites } );
          setLoading(false);
        }
      );
    }
  }, [mounted, setData]);

  return (
    <Box display="flex" flexDirection="column" height="100%" p={4} bgcolor="#1E1E2E">
      <Box display="flex" justifyContent="space-between" alignItems="end" mb={1.875}>
        <Typography fontSize={35} fontWeight={700} children="Manage Users" />
        <Can I="create" a="user">
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ color: "#FFFFFF" }}
            onClick={() => setOpenAddModal(true)}
            children="+ Add User"
          />
        </Can>
      </Box>

      <UsersTable
        loading={loading}
        roles={options.roles}
        data={data}
        userId={user?.uid}
        onEdit={(data) => setEditingData(data)}
        onEditRole={handleEditRole}
      />

      {openAddModal && (
        <AddUserModal
          open={openAddModal}
          options={options}
          onAdd={handleAdd}
          onClose={() => setOpenAddModal(false)}
        />
      )}

      {!!editingData && (
        <EditUserModal
          open={true}
          options={options}
          user={editingData}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onClose={() => setEditingData(undefined)}
          getReferences={ getUserReferences( apiClient ) }
        />
      )}
    </Box>
  );
};

export default ManageUsers;
