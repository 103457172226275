export default {
  variants: [
    {
      props: { color: "primary" },
      style: {
        "& .MuiOutlinedInput-notchedOutline": {
          color: "#FFFFFF",
          borderColor: "#FFFFFF",
        },
      },
    },
    {
      props: { size: "medium" },
      style: {
        "&.MuiInputBase-root": {
          height: 48,
        },
      },
    },
  ],
};