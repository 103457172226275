import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 25,
    borderRadius: 12.5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 12.5,
        backgroundColor: '#4D7DF2',
    },
}));

interface Props{
    checkpointPtr: number;
    totalCheckpoint: number;
}

const CustomizedProgressBar: React.FC<Props> = ({ checkpointPtr, totalCheckpoint }) => {
    const theme = useTheme();
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
              <BorderLinearProgress variant="determinate" color='primary' value={checkpointPtr / totalCheckpoint * 100}  />
          </Box>
          <Box sx={{ minWidth: 35, color: theme.palette.primary.main, textAlign: 'center' }}>
              <Typography variant="body2" data-testid='vp-detail-progress' >
                  { `${ checkpointPtr }/${ totalCheckpoint }` }
              </Typography>
          </Box>
      </Box>
    );
}


export default CustomizedProgressBar;