import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';

type SectionProps = {
  title: JSX.Element,
  children: React.ReactNode,
}

const sectionStyles = StyleSheet.create( {
  header: {
    backgroundColor: 'rgba( 64, 64, 64, 0.5 )',
    color: 'white',
  },
  body: {
    borderColor: 'white'
  }
} ); 

const Section =( props: SectionProps ) => (
  <View>
    <View style={ sectionStyles.header }>
    { props.title }
    </View>
    <View>
      { props.children }
    </View>
  </View>
);

export default Section;