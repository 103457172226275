export default {
  styleOverrides: {
    root: {
      textTransform: "none",
    },
  },
  variants: [
    {
      props: { color: "primary" },
      style: {
        backgroundColor: "#4D7DF2",
        color: "#FFFFFF",
        fontSize: "14px",
        lineHeight: "17px",
      },
    },
  ],
}