import { getScheduleApi } from "./getSchedule";

// utils
import { recurrenceUtil } from "../../../utils/recurrence";

// types
import { ScheduleFormData } from "../types";

export const editScheduleApi = ( apiInstance: API.Client ) => async (data: ScheduleFormData & { uid: string }) => {
  const updateSchedulePromise = apiInstance.Entity.collection( 'schedule' ).update(data.uid, {
    scheduleRule: recurrenceUtil.toString(data.scheduleRule),
    scheduleTime: data.scheduleTime,
    routeRefId: data.route,
    assignOperatorRefIds: data.operators,
  });

  await Promise.all([updateSchedulePromise]);

  return getScheduleApi( apiInstance )(data.uid);
};
