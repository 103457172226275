export default async ( apiInstance: API.Client ) => {
  const incidentTypes = ( 
    await apiInstance.Map?.collection( 'incidentType' )
      .getAll() 
  ) || [];
  const priorityTypes =  ( 
    await apiInstance.Map?.collection( 'priorityType' ) 
      .getAll()
  ) || [];
  const priorityMap = new Map( priorityTypes.map( pType => [ ( pType as unknown as DataModel.PriorityType ).key, pType ] ) );

  return incidentTypes.map(
    ( iType: JSON ) => {
      const iTypeInstance = iType as unknown as DataModel.IncidentType;
      const priority = priorityMap.has( iTypeInstance.priorityKeyRef )
        ? priorityMap.get( iTypeInstance.priorityKeyRef )
        : null;
      return {
        ...iType,
        priority
      };
    } 
  );
}