import { useState, FC, SVGProps, useLayoutEffect } from "react";

// icons
import icons from "../assets/icons";

export interface IconSvgProps extends SVGProps<SVGSVGElement> {
  icon: keyof typeof icons;
}

const IconSvg: FC<IconSvgProps> = ({ icon, ...props }) => {
  const [instance, setInstace] = useState<{ ReactComponent: FC<SVGProps<SVGSVGElement>> }>();
  useLayoutEffect(() => {
    icons?.[icon]?.ReactComponent && setInstace(icons[icon]);
  }, [icon]);

  if (!instance) return <></>;

  return <instance.ReactComponent {...props} />;
};

export default IconSvg;
