import { Navigate } from 'react-router-dom';
import User from './views/Users';
import Checkpoint from './views/Checkpoints';
import Dashboard from './views/Dashboard';
import Profile from './views/Profile';
import Route from './views/Routes';
import Schedule from './views/Schedules';
import DataLog from './views/DataLogs';
import Report from './views/Reports';

import { AccountUserClientType } from './clients/AccountUser';
import { SummaryClient } from './clients/SummaryReportApi';
export default (
  apiClient: API.Client,
  accountUserClient: AccountUserClientType,
  summaryReport: SummaryClient
) => ( {
  main: {
    label: 'Virtual Patrol',
    name: 'main',
    path: '/',
    private: true,
    element: <Dashboard accountUserClient={ accountUserClient } apiInstance={ apiClient } />
  },
  log: {
    label: 'Log',
    name: 'dataLog',
    path: '/log/*',
    link: '/log',
    private: true,
    element: <DataLog apiClientInstance={apiClient} accountUserClient={accountUserClient} />
  },
  report: {
    label: 'Report',
    name: 'report',
    path: '/report',
    private: true,
    element: <Report apiClientInstance={apiClient} summaryReport={ summaryReport } />
  },
  user: {
    label: 'User',
    name: 'user',
    path: '/user',
    private: true,
    element: <User accountUserClient={accountUserClient} apiClient={ apiClient } />
  },
  checkpoint: {
    label: 'Checkpoint',
    name: 'checkpoint',
    path: '/checkpoint',
    private: true,
    element: <Checkpoint apiClientInstance={ apiClient } />
  },
  route: {
    label: 'Route',
    name: 'route',
    path: '/route',
    private: true,
    element: <Route apiClientInstance={ apiClient } />
  },
  schedule: {
    label: 'Schedule',
    name: 'schedule',
    path: '/schedule',
    private: true,
    element: <Schedule apiClientInstance={ apiClient } />
  },
  profile: {
    name: 'profile',
    path: '/profile',
    private: true,
    element: <Profile accountUserClient={ accountUserClient } apiClient={ apiClient } />
  },
  logout: {
    name: 'logout',
    path: '/logout',
    private: true,
    element: <Navigate to="/" replace={ true } />
  }
} as unknown as Application.RouterConfig );