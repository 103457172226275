import React from 'react';
import { Ability } from '@casl/ability';

import {
  Routes,
  Route,
} from "react-router-dom";

import { AbilityContext } from './Context/Ability';

import Layout from './components/Layout';
import RestrictedPage from './components/RestrictedPage';

import LoadingPage from './views/LoadingPage';

import { AccountUserClientType } from './clients/AccountUser';
import Patrol from './views/Patrol';
import { BridgeClient } from './clients/BridgeApi';
import ViewDocument from './views/Reports/View';

type AppRouterProp = {
  videoConfig: { baseAddress: string, path: string },
  apiConfig: { domain: string, service: string },
  apiClient: API.Client,
  bridgeClient: BridgeClient,
  accountUserClient: AccountUserClientType,
  routerConfig: Application.RouterConfig
};

const AppRouter = ( props: AppRouterProp ) => {
  const { apiClient, bridgeClient, accountUserClient, routerConfig, videoConfig } = props;
  const [ loading, setLoading ] = React.useState( true );
  const [ authenticated, setAuthenticated ] = React.useState( false );
  const [ ability, setAbility ] = React.useState( new Ability( [] ) );  
  
  const [ user, setUser ] = React.useState<DataModel.User | null>( null );

  const userEffectFn = React.useCallback( async () => {
    if ( !authenticated ) {
      const resultUser = await accountUserClient.get() as unknown as {
        user: DataModel.User
      };
      if ( !resultUser || !resultUser.user ) {
        const redirectLink = await accountUserClient.getLoginLink( window.location.href );
        window.location.replace( redirectLink );          
      } else {
        setUser( resultUser.user );
        setAuthenticated( true );
      }
    }
  }, [ authenticated ] );

  React.useEffect(
    () => {
      userEffectFn();
    },
    [ user ]  
  );

  React.useEffect( () => {
    async function abilityEffectFn () {
      if ( user ) {
        const resultAbility = await apiClient.Entity.ability();
        if ( resultAbility ){
          setAbility( new Ability( resultAbility ) );
        }
        setLoading( false );
      }
    }
    abilityEffectFn();
  }, [ user ] );
  
  const routeList = Object.values( routerConfig )
    .filter( route => route.private );

  const onLogout = ( ) => {
    setUser( null );
    setAuthenticated( false ); 
  }

  if ( loading ) {
    return (
      <LoadingPage />
    );
  }
  return (
    <AbilityContext.Provider value={ability}>
        <Routes>
          <Route element={<Layout routerConfig={routerConfig} accountUserClient={accountUserClient} onLogout={ onLogout } />}>
            { 
              routeList
                .map( ( route, index ) => (
                <Route
                  key={ index }
                  path={route.path}
                  element={
                    <RestrictedPage
                      I={ route.action ? route.action : "read" }
                      a={ route.name }
                      element={ route.element } />
                  }
                  />
              ) )
            }
          </Route>
          <Route 
            path="/patrol/:uid/:time"
            element={
              <RestrictedPage
                I="write"
                a="patrol"
                element={
                  <Patrol
                    apiInterface={apiClient}
                    bridgeClient={bridgeClient}
                    accountUserClient={accountUserClient}
                    config={ videoConfig }
                  />
                }
              />
            }
          />
          <Route
            path="/report/:uid"
            element={
              <RestrictedPage
                I="read"
                a="patrol"
                element={
                  <ViewDocument
                    apiInstance={apiClient}
                  />
                }
              />
            }
          />
        </Routes>
    </AbilityContext.Provider>
  )
};

export default AppRouter;