import {
  TableRow,
  TableCell,
} from '@mui/material';

import { Table } from "../../../types/components/Table";

export type LoadingOrEmptyTablePropType<TData> = {
  loading ?: boolean,
  columns: Table.Column<TData>[],
};

const LoadingOrEmpty = <TData extends Record<string, any>>( prop: LoadingOrEmptyTablePropType<TData> ) => {
  const { loading, columns } = prop;

  return (
    <TableRow key="status">
      <TableCell colSpan={columns.length} sx={{ textAlign: "center", borderWidth: 0 }}>
        { loading ? "Loading..." : "No Records" }
      </TableCell>
    </TableRow>
  );
}

export default LoadingOrEmpty;


