import pLimit from "p-limit";

import { AccountUserClientType } from "../AccountUser";

export type ApiClientType = {
  get: ( path: string ) => Promise<Response>,
  post: ( path: string, body: JSON ) => Promise<Response>,
  postText: ( path: string, body: string ) => Promise<Response>,
  put: ( path: string, body: JSON ) => Promise<Response>,
  patch: ( path: string, body: JSON ) => Promise<Response>,
  delete: ( path: string ) => Promise<Response>,
}

const createClient = ( config: Application.ConfigType ) => (
  accountUserClient: AccountUserClientType
) => {
  const {
    baseUrl, domain, service
  } = config.api as {
    baseUrl: string, domain: string, service: string 
  };
  const limit = pLimit( 6 );  

  const getSendOptions = ( tokenObj: { type: string, token: string }, postForm = false ) => {
    const initialOption = {
      headers: {
        authorization: `Bearer ${ tokenObj.token }`,
      },
      withCredentials: true,
    };

    return postForm
      ? { 
        ...initialOption,
        headers: {
          ...initialOption.headers,
          'Content-Type': 'application/json'
        }
      }
      : initialOption;
  };

  return {
    get: ( path: string ) => (
      accountUserClient.getToken( domain, service )
        .then( token => (
          limit( () => fetch( `${baseUrl}${path}`, {
            ...getSendOptions( token ),
            method: 'GET'
          } as RequestInit )
        ) ) )
    ),
    post: ( path: string, body: JSON ) => (
      accountUserClient.getToken( domain, service )
        .then( token => (
          limit( () => fetch( `${ baseUrl }${ path }`, {
            ...getSendOptions( token, true ),
            method: 'POST',
            body: JSON.stringify( body ),
          } as RequestInit ) )
        ) )
    ),
    postText: ( path: string, body: string ) => (
      accountUserClient.getToken( domain, service )
        .then( token => {
          const initSendOptions = getSendOptions( token, true );
          const sendOptions = {
            ...initSendOptions,
            headers: {
              ...initSendOptions.headers,
              'Content-Type': 'text/plain'
            }
          };
          return limit( () => fetch( `${ baseUrl }${ path }`, {
            ...sendOptions,
            method: 'POST',
            body,
          } as RequestInit ) );
        } )
    ),
    put: ( path: string, body: JSON ) => (
      accountUserClient.getToken( domain, service )
        .then( token => (
          limit( () => fetch( `${ baseUrl }${ path }`, {
            ...getSendOptions( token, true ),
            method: 'PUT',
            body: JSON.stringify( body ),
          } as RequestInit ) )
        ) )
    ),
    patch: ( path: string, body: JSON ) => (
      accountUserClient.getToken( domain, service )
        .then( token => (
          limit( () => fetch( `${ baseUrl }${ path }`, {
            ...getSendOptions( token, true ),
            method: 'PATCH',
            body: JSON.stringify( body ),
          } as RequestInit ) )
        ) )
    ), 
    delete: ( path: string ) => (
      accountUserClient.getToken( domain, service )
        .then( token => (
          limit( () => fetch( `${ baseUrl }${ path }`, {
            ...getSendOptions( token ),
            method: 'DELETE',
          } as RequestInit ) )
        ) )
    ),
  };
};

export default createClient;