import { getRouteApi } from "./getRoute";
import { RouteFormDataType, RouteDataType } from "../types";

export const editRouteApi = ( apiInstance: API.Client ) => async (
  data: RouteFormDataType & { uid: string; removedCheckpoints: DataModel.Checkpoint[] }
) => {
  const { Entity } = apiInstance
  
  const updateRoutePromise = Entity.collection( 'route' ).update( data.uid, {
    uid: data.uid,
    name: data.name,
    siteRefId: data.site,
    routeTypeKeyRef: data.routeType,
    checkpointRefIds: data.checkpoints.map( checkpoint => checkpoint.uid ),
  } as unknown as JSON );

  await updateRoutePromise;

  return getRouteApi( apiInstance )( data.uid ) as unknown as RouteDataType;
};
