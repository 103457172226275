import { Components } from "@mui/material";
import MuiAppBar from "./MuiAppBar";
import MuiButton from "./MuiButton";
import MuiList from "./MuiList";
import MuiListItem from "./MuiListItem";
import MuiMenuItem from "./MuiMenuItem";
import MuiSelect from "./MuiSelect";
import MuiTableCell from "./MuiTableCell";
import MuiTextField from "./MuiTextField";

export default {
  MuiAppBar,
  MuiButton,
  MuiList,
  MuiListItem,
  MuiMenuItem,
  MuiSelect,
  MuiTableCell,
  MuiTextField,
} as Components