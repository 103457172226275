import React, { useState, useEffect } from 'react';
import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';

import ReportDocument from './ReportDocument';
import { getPatrolDetails } from './action/getPatrolDetails';

const viewerStyle = StyleSheet.create( {
  viewer: {
    width: '100vw',
    height: '100vh'
  }
} )

const ViewDocument = ( props: any ) => {
  const { apiInstance } = props;
  const { uid } = useParams();

  const [ patrol, setPatrol ] = useState<any>( null );
  useEffect( () => {
    async function load(){
      if ( uid ) {
        const result = await getPatrolDetails( apiInstance )( uid );
        const resultWithImage = await result.checkpoints.reduce(
          ( pAcc: Promise<any[]>, checkpoint: any ) => pAcc.then( ( aResult: any[] ) => {
            if ( checkpoint.image ) {
              return apiInstance.Image?.get( checkpoint.image )
              .then( ( imageBlob: Blob | undefined ) => {
                const updatedCheckpoint = imageBlob
                  ? { ...checkpoint, imageUrl: URL.createObjectURL( imageBlob ) }
                  : checkpoint;
                return [ ...aResult, updatedCheckpoint ];
              } );
            }
            return Promise.resolve( [ ...aResult, checkpoint ] );
          } ),
          Promise.resolve( [] ),
        ).then( ( checkpointsResult: any[] ) => (
          {
            ...result,
            checkpoints: checkpointsResult
          }
        ) );
        
        setPatrol( resultWithImage );
      }
    }
    load();
  }, [ uid ] );
  if ( patrol ) {
    return (
        <PDFViewer style={viewerStyle.viewer} >
          <ReportDocument patrol={patrol} />
        </PDFViewer>
    )
  }
  return <></>;
};

export default ViewDocument;