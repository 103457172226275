import { FC, useState, useEffect } from "react";
import { useForm } from "react-hook-form";

// components
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { ReportRounded } from "@mui/icons-material";
import Modal, { ModalProps } from "../../components/ModalDialogue";
import ModalHeader from "../../components/ModalDialogue/Header";
import ModalFooter from "../../components/ModalDialogue/Footer";
import ModalBody from "../../components/ModalDialogue/Body";
import IconSvg from "../../components/IconSvg";
import UserForm from "./UserForm";
import { Can } from "../../Context/Ability";

// types
import { UserDisplayData, UserFormData, UserOptions } from "./types";

export interface EditUserModalProps extends Omit<ModalProps, "children"> {
  user: UserDisplayData;
  options: UserOptions;
  onDelete: (uid: string) => void;
  onEdit: (user: UserFormData & { uid: string }) => void;
  getReferences: ( uid: string ) => Promise<{ entities: string[] }>
}

const EditUserModal: FC<EditUserModalProps> = ({
  open,
  user,
  options,
  onDelete,
  onEdit,
  onClose,
  getReferences,
  ...props
}) => {
  const form = useForm<UserFormData>({
    mode: "onBlur",
    defaultValues: { displayName: "", username: "", role: "", siteIds: [] },
  });
  const { setValue, handleSubmit } = form;

  const handleClose = () => onClose?.({}, "backdropClick");

  const handleDelete = () => {
    onDelete(user.uid);
    handleClose();
  };

  const handleEdit = handleSubmit((data) => {
    onEdit({ ...data, uid: user.uid });
    handleClose();
  });

  const [ hasReferences, setHasReferences ] = useState( false );
  useEffect( () => {
    async function updateHasReferences(){ 
      const refResult = await getReferences( user.uid );
      setHasReferences( refResult && refResult.entities.length > 0 );
    }
    updateHasReferences();
  }, [ user.uid ] )

  useEffect(() => {
    setValue("displayName", user.displayName);
    setValue("username", user.username);
    setValue("role", user.role);
    setValue("siteIds", user.siteIds);
  }, [setValue, user]);

  return (
    <Modal open={open} boxProps={{ height: 800 }} onClose={onClose} {...props}>
      <>
        <ModalHeader children="Edit User" />

        <ModalBody>
          <UserForm form={form} options={options} />
        </ModalBody>

        <ModalFooter>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box>
              <Can I="delete" a="user">
                <Button variant="text" color="error" disabled={ hasReferences } onClick={handleDelete}>
                  <IconSvg icon="trash" style={{ marginRight: 10 }} />
                  Delete
                </Button>
                {
                  hasReferences && (
                    <Tooltip title="Entry is referenced by other Entities." placement="top">
                      <IconButton aria-label="info">
                        <ReportRounded />
                      </IconButton>
                    </Tooltip>
                  )
                }
              </Can>
            </Box>

            <Box>
              <Button variant="text" color="secondary" children="Cancel" onClick={handleClose} />
              <Button variant="contained" sx={{ ml: 2 }} children="Update" onClick={handleEdit} />
            </Box>
          </Box>
        </ModalFooter>
      </>
    </Modal>
  );
};

export default EditUserModal;
