import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Weekday from 'dayjs/plugin/weekday';
import { rrulestr } from 'rrule';
import * as R from 'ramda';

dayjs.extend( Weekday );
dayjs.extend( utc );

export default ( apiInstance: API.Client ) => async ( user: DataModel.User, dateTime: number ) => {
  const scheduleList = await apiInstance.Entity.collection( 'schedule' )
    .getAll();

  const assignedList = ( user.role === 'operator' )
    ? scheduleList.filter(
      ( entry: DataModel.Schedule ) => entry.assignOperatorRefIds.includes( user.uid )
    )
    : scheduleList;
  
  const range = [
    dayjs.unix( dateTime ).local().startOf( 'day' ).toDate(),
    dayjs.unix( dateTime ).local().endOf( 'day' ).toDate(),
  ];

  return assignedList.filter(
    ( entry: DataModel.Schedule ) => {
      const rruleSet = rrulestr(
        R.join( '\n' )( [
          `DTSTART:${ dayjs.unix( dateTime ).local().startOf( 'day' ).format( 'YYYYMMDDTHHmmss[Z]' ) }`,
          `RRULE:${ entry.scheduleRule }`
        ] )
      );
      const result = rruleSet.between( range[ 0 ], range[ 1 ], true ).length > 0;
      return result;
    }
  );
}