// api instance
import { CheckpointOptionsType } from "../types";

export const getCheckpointOptionsApi = async ( apiInstance: API.Client ) => {
  const sitePromise = apiInstance.Entity.collection( 'site' ).getAll();
  const cameras = await apiInstance.Entity.collection( 'camera' ).getAll();
  const cameraOptionsPromise = Promise.all(
    cameras.map( ( camera: DataModel.Camera ) => apiInstance.Entity
      .collection( 'camera' )
      .getReferences( camera.uid )
      .then( ( refResult: { entities: string[] } ) => ( {
        camera,
        referenced: refResult.entities.includes( 'checkpoint' )
      } ) ) 
    )
  )

  const [ sites, cameraOptions ] = await Promise.all([
    sitePromise,
    cameraOptionsPromise,
  ]);
  
  return { sites, cameras: cameraOptions } as CheckpointOptionsType;
};
