import { FC, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

// components
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { ReportRounded } from "@mui/icons-material";
import Modal, { ModalProps } from "../../components/ModalDialogue";
import ModalHeader from "../../components/ModalDialogue/Header";
import ModalFooter from "../../components/ModalDialogue/Footer";
import ModalBody from "../../components/ModalDialogue/Body";
import IconSvg from "../../components/IconSvg";
import ScheduleForm from "./ScheduleForm";
import { Can } from "../../Context/Ability";

// utils
import { recurrenceUtil } from "../../utils/recurrence";

// types
import { ScheduleFormData, ScheduleOptions, ScheduleDisplayData } from "./types";

dayjs.extend( utc );

export type EditScheduleModalProps = Omit<ModalProps, "children"> & {
  schedule: ScheduleDisplayData,
  isOptionLoaded: boolean,
  options: ScheduleOptions,
  onDelete: (uid: string) => void,
  onEdit: (schedule: ScheduleFormData & { uid: string }) => void,
  getReferences: ( uid: string ) => Promise<{ entities: string[] }>
}

const EditScheduleModal: FC<EditScheduleModalProps> = ({
  open,
  schedule,
  isOptionLoaded,
  options,
  onDelete,
  onEdit,
  onClose,
  getReferences,
  ...props
}) => {
  const form = useForm<ScheduleFormData>({
    mode: "onBlur",
    defaultValues: { scheduleRule: [], scheduleTime: "", route: "", operators: [] },
  });
  const { setValue, handleSubmit } = form;

  const handleClose = () => onClose?.({}, "backdropClick");

  const handleDelete = () => {
    onDelete(schedule.uid);
    handleClose();
  };

  const handleEdit = handleSubmit((data) => {
    onEdit({ ...data, uid: schedule.uid });
    handleClose();
  });

  const [ hasReferences, setHasReferences ] = useState( false );
  useEffect( () => {
    async function updateHasReferences () {
      const refResult = await getReferences( schedule.uid );
      setHasReferences( refResult && refResult.entities.length > 0 );
    }
    updateHasReferences();
  }, [ getReferences, schedule.uid ] )

  useEffect(() => {
    // Because form value will be set to [Select] component,
    //   so when options are empty but [Select] has value, MUI will throw warning
    // We have to wait until options were loaded
    // Note: when options are empty (Ex: api didn't return data), MUI will throw warning
    if (isOptionLoaded) {
      let weekDays = recurrenceUtil.parseToList(schedule.scheduleRule);

      if (!weekDays.length) weekDays = recurrenceUtil.days;
      setValue(
        "scheduleRule",
        weekDays.map(({ weekday }) => weekday)
      );
      const fullDate = `${ dayjs().startOf( 'year' ).format( 'YYYY-MM-DD' )}T${ schedule.scheduleTime }`;

      setValue("scheduleTime", dayjs(fullDate).local().format("HH:mm"));
      schedule.route && setValue( "route", schedule.route.uid );
      schedule.operators &&
        setValue(
          "operators",
          schedule.operators.map(( { uid } ) => uid ) 
        );
    }
  }, [isOptionLoaded, setValue, schedule]);

  return (
    <Modal open={open} boxProps={{ height: 800 }} onClose={onClose} {...props}>
      <>
        <ModalHeader children="Edit Schedule" />

        <ModalBody>
          <ScheduleForm form={form} options={options} />
        </ModalBody>

        <ModalFooter>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box>
              <Can I="delete" a="schedule">
                <Button variant="text" color="error" disabled={ hasReferences } onClick={handleDelete}>
                  <IconSvg icon="trash" style={{ marginRight: 10 }} />
                  Delete
                </Button>
                {
                  hasReferences && (
                    <Tooltip title="Entry is referenced by other Entities." placement="top">
                      <IconButton aria-label="info">
                        <ReportRounded />
                      </IconButton>
                    </Tooltip>
                  )
                }
              </Can>
            </Box>

            <Box>
              <Button variant="text" color="secondary" children="Cancel" onClick={handleClose} />
              <Button variant="contained" sx={{ ml: 2 }} children="Update" onClick={handleEdit} />
            </Box>
          </Box>
        </ModalFooter>
      </>
    </Modal>
  );
};

export default EditScheduleModal;
