import { RouteDataType } from "../types";

export const getRoutesApi = async ( apiInstance: API.Client ) => {
  const routeResults = ( await apiInstance.Entity.collection( 'route' ).getAll() ) as unknown as DataModel.Route[];

  const routeResultsIncludePromise = routeResults.map(async ( route, index ) => {
    const site = await apiInstance.Entity.collection( 'site' ).get(route.siteRefId);
    const routeType = await apiInstance.Map?.collection( 'routeType' ).get(route.routeTypeKeyRef);
    const checkpoints = await Promise.all(
      route.checkpointRefIds.map( uid => (
        apiInstance.Entity.collection( 'checkpoint' ).get( uid )
      ) )
    );

    return {
      ...route,
      no: index + 1,
      site,
      routeType,
      checkpoints,
    } as RouteDataType;
  });

  return Promise.all( routeResultsIncludePromise );
};
