import { FC } from "react";
import { useForm } from "react-hook-form";

// components
import { Button } from "@mui/material";
import Modal, { ModalProps } from "../../components/ModalDialogue";
import ModalHeader from "../../components/ModalDialogue/Header";
import ModalFooter from "../../components/ModalDialogue/Footer";
import ModalBody from "../../components/ModalDialogue/Body";
import CheckpointForm from "./CheckpointForm";

// types
import { CheckpointFormData, CheckpointOptionsType } from "./types";

export interface AddChecklistModalProps extends Omit<ModalProps, "children"> {
  options: CheckpointOptionsType;
  onAdd: (route: CheckpointFormData) => void;
}

const AddChecklistModal: FC<AddChecklistModalProps> = ({ options, onAdd, onClose, ...props }) => {
  const form = useForm<CheckpointFormData>({
    mode: "onBlur",
    defaultValues: { name: "", description: "", site: "", camera: "", checklistItems: [] },
  });
  const { handleSubmit } = form;

  const handleClose = () => onClose?.({}, "backdropClick");

  const handleAdd = handleSubmit((data) => {
    onAdd(data);
    handleClose();
  });

  return (
    <Modal boxProps={{ height: 800 }} onClose={onClose} {...props}>
      <>
        <ModalHeader children="Add Checkpoint" />

        <ModalBody>
          <CheckpointForm form={form} options={options} />
        </ModalBody>

        <ModalFooter>
          <Button variant="text" color="secondary" children="Cancel" onClick={handleClose} />
          <Button variant="contained" sx={{ ml: 2 }} children="Add" onClick={handleAdd} />
        </ModalFooter>
      </>
    </Modal>
  );
};

export default AddChecklistModal;
