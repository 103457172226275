import { useController, UseControllerProps } from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";

export type CustomTextFieldProps<FormDataType> = UseControllerProps<FormDataType> & TextFieldProps;

const CustomTextField = <FormDataType extends Record<string, any>>({
  control,
  name,
  rules,
  defaultValue,
  size = "medium",
  color = "primary",

  ...props
}: CustomTextFieldProps<FormDataType>) => {
  const { field, formState } = useController({ control, name, rules, defaultValue });

  let sx = { ...props.sx };

  if (size === "medium") {
    sx = { ...sx, "& .MuiInputBase-input": { height: 15 } };
    if (!field.value) {
      sx = {
        ...sx,
        "& .MuiInputLabel-root:not(.Mui-focused)": { transform: "translate(14px, 12px) scale(1)" },
      };
    }
  }

  return (
    <TextField
      size={size}
      color={color}
      {...props}
      sx={sx}
      error={!!formState.errors[name]}
      {...field}
    />
  );
};

export default CustomTextField;
