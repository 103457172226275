import React from 'react';
import { Paper, Box, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import * as R from 'ramda';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import getScheduleList from './action/getScheduleList';

import DashboardTable from './DashboardTable';

import { AccountUserClientType } from '../../clients/AccountUser';
import { ScheduleDisplayData } from './types';
import useClock from '../../hooks/useClock';
import useUser from '../../hooks/useUser';
import loadScheduleDisplayData from './action/loadScheduleDisplayData';
import loadScheduledPatrolStatus from './action/loadScheduledPatrolStatus';

type DashboardProps = {
  accountUserClient: AccountUserClientType,
  apiInstance: API.Client,
};

dayjs.extend( utc );

const Dashboard = ( props: DashboardProps ) => {
  const { apiInstance, accountUserClient } = props;
  const [ loading, setLoading ] = React.useState( true );

  const currentTime = useClock();

  const { user, loginTime }  = useUser( accountUserClient );

  const getDateList = () => R.map( 
    ( i: number ) => dayjs().local().add( i - 1, 'day' ) 
  )( R.range( 0, 6 ) );

  const [ dateList, setDateList ] = React.useState( getDateList() );
  React.useEffect( () => {
    const currentTimeObj = dayjs.unix( currentTime ).local();
    const minTimeObj = dateList[ 1 ];
    if ( currentTimeObj.startOf( 'day' ).isAfter( minTimeObj.startOf( 'day' ) ) ) {
      setDateList( getDateList() );
    }
  }, [ currentTime ] );
  
  const [ dateIdx, setDateIdx ] = React.useState( 1 );
  const handleDateChange = ( event: SelectChangeEvent<number> ) => {
    const { value } = event.target;
    setDateIdx( value as number );
  };

  const [ scheduleList, setScheduleList ] = React.useState<ScheduleDisplayData[]>( [] );
  React.useEffect( () => {
    const loadScheduleFn = async () => {
      if ( user && user.uid !== '' ){
        setLoading( true );
        const ptrDate = dateList[ dateIdx ].unix();
        const scheduleResult: DataModel.Schedule[] = await getScheduleList( apiInstance )(
          user, ptrDate
        );
        const scheduleDisplayList = await loadScheduleDisplayData( apiInstance )( scheduleResult, ptrDate );
        const scheduleListFilled = await loadScheduledPatrolStatus( apiInstance )( scheduleDisplayList );
        setScheduleList( scheduleListFilled );
        setLoading( false );
      }
    }
    loadScheduleFn();
  }, [ user, dateList[ dateIdx ], apiInstance ] )

  React.useEffect( () => {
    const updateScheduleListFn = async () => {
      if ( dayjs.unix( currentTime ).local().second() % 5 === 0 && !loading ) {
        const newScheduleListResult = await loadScheduledPatrolStatus( apiInstance )( scheduleList );
        setScheduleList( newScheduleListResult ); 
      }
    }
    updateScheduleListFn();
  }, [ currentTime, loading ] );

  const listWithTime = scheduleList.map( entry => ( { ...entry, currentTime } ) );
  const dateListOptionsCount = user.role === 'operator'
    ? 2 : 6;
  
  return (
    <Paper>
      <Box>
        {
          ( user && user.role !== 'customer' ) && (
            <>
              <Select
                label=""
                value={ dateIdx }
                onChange={ handleDateChange }
              >
                { R.range( 0, dateListOptionsCount ).map( i => (
                  <MenuItem key={i} value={i}>
                    { dateList[ i ].format( 'DD MMM YYYY' ) }
                  </MenuItem>
                ) ) }
              </Select>
              <DashboardTable
                loading={ loading }
                data={ listWithTime }
                user={ user }
                loginTime={ loginTime }
                apiInstance={ apiInstance }
              />
            </>
            
          )
        }
      </Box>
    </Paper>
  )

};

export default Dashboard;
