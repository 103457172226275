import {
  TableCellProps,
  TableCell,
  TableSortLabel,
  SxProps,
} from "@mui/material";

import { Table } from '../../../types/components/Table';

type TableHeaderColumnProps<TData> = {
  column: Table.Column<TData>,
  isLessBorder ?: boolean,
  order ?: Table.Order,
  orderBy ?: string,
  onSort ?: ( id: string ) => void,
}

const HeaderColumn = <TData extends Record<string, any>>( props: TableHeaderColumnProps<TData> ) => {
  const { column, isLessBorder, order, orderBy, onSort } = props;
  const cellProps: TableCellProps = {
    sortDirection: orderBy === column.id ? order : false,
    width: column.width,
    ...column.tableCellProps,
    ...column.tableHeaderCellProps,
    sx: {
      fontWeight: 700,
      color: "rgba(255, 255, 255, 0.5)",
      borderBottom: isLessBorder ? "none" : undefined,
      ...column.tableCellProps?.sx,
      ...column.tableHeaderCellProps?.sx,
    } as SxProps,
  };

  return (
    <TableCell key={column.id} {...cellProps}>
      {column.sortable ? (
        // sortable
        <TableSortLabel
          active={orderBy === column.id}
          direction={orderBy === column.id ? order : "asc"}
          sx={{
            "&.Mui-active, &.Mui-active .MuiTableSortLabel-icon": { color: "inherit" },
          }}
          onClick={() => onSort?.(column.id)}
        >
          {column.header}
        </TableSortLabel>
      ) : (
        // non sortable
        column.header
      )}
    </TableCell>
  );
}


export default HeaderColumn;