import * as R from "ramda";

export default ( apiInstance: API.Client ) => async ( entry: any ): Promise<string> => {
  const checkpointImages = entry.checkpoints
    .map(
      ( checkpoint: any ) => ( {
      ...checkpoint,
      imageName: `p${ entry.scheduleUid }_s${ entry.scheduledDateTime }_c${ checkpoint.uid }.png`,
      } )
    );

  await checkpointImages
    .filter( ( imgObj: any ) => R.has( 'image', imgObj ) )
    .reduce(
      ( pAcc: Promise<any>, imageObj: any ) => (
        pAcc.then( () => (
          apiInstance.Image?.upload( imageObj.imageName, imageObj.image )
        ) )
      ),
      Promise.resolve( '' ) 
    );

  const entryForm = {
    ...entry,
    checkpoints: checkpointImages.map( ( checkpoint: any ) => {
      const initSpec = {
        uid: checkpoint.uid,
        name: checkpoint.name, 
        notes: checkpoint.notes,
        timestamp: checkpoint.timestamp,
        checklist: checkpoint.checklist,
        incidents: checkpoint.incidents,
      };
      return checkpoint.image
        ? { ...initSpec, image: checkpoint.imageName }
        : initSpec;
    } ),
  };
  const uid = await apiInstance.Entity.collection( 'patrol' )
    .add( entryForm )
  return uid;
}