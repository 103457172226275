import { RouteDataType } from "../types";

export const getRouteApi = ( apiInstance: API.Client ) => async (uid: string): Promise<RouteDataType | null> => {
  
  const routeResult: DataModel.Route = await apiInstance.Entity?.collection( 'route' ).get( uid );
  if ( !routeResult ) return null;
  const { siteRefId, routeTypeKeyRef, ...routeOtherInfo } = routeResult;

  const siteResultIncludePromise = apiInstance.Entity?.collection( 'site' ).get( siteRefId );
  const routeTypeResultIncludePromise = apiInstance.Map?.collection( 'routeType' ).get( routeTypeKeyRef );
  const checkpointsResultIncludePromise: Promise<DataModel.Checkpoint[]> = (
    Promise.all( routeResult.checkpointRefIds.map( uid => (
      apiInstance.Entity.collection( 'checkpoint' ).get( uid )  
    ) ) )
  );

  const [site, routeType, checkpoints] = await Promise.all([
    siteResultIncludePromise,
    routeTypeResultIncludePromise,
    checkpointsResultIncludePromise,
  ]);
  console.log( checkpoints, 'checkpoint result' );
  return {
    ...routeOtherInfo,
    no: 0,
    site,
    routeType,
    checkpoints,
  } as RouteDataType;
};
