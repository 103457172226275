import { useEffect, useState } from "react";

// components
import { Box, Button, Typography } from "@mui/material";

import RoutesTable from "./RoutesTable";
import AddRouteModal from "./AddRouteModal";
import EditRouteModal from "./EditRouteModal";
import { Can } from "../../Context/Ability";

// apis
import {
  addRouteApi,
  editRouteApi,
  getRoutesApi,
  deleteRouteApi,
  getRouteOptionsApi,
  getRouteReferences,
} from "./action";

import { RouteDataType, RouteFormDataType, RouteOptionsType } from "./types";

type ManageRoutesProps = {
  apiClientInstance: API.Client,
};

const ManageRoutes = ( props: ManageRoutesProps ) => {
  const { apiClientInstance } = props;
  const [openAddModal, setOpenAddModal] = useState(false);

  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isOptionLoaded, setOptionLoaded] = useState(false);

  const [data, setData] = useState<RouteDataType[]>([]);
  const [editingData, setEditingData] = useState<RouteDataType | undefined>();
  const [options, setOptions] = useState<RouteOptionsType>({
    sites: [],
    routeTypes: [],
    checkpoints: [],
  });

  const handleAdd = (route: RouteFormDataType) => {
    addRouteApi( apiClientInstance )(route).then((res) => {
      res && setData([...data, { ...res, no: data.length + 1 }]);
    });
  };

  const handleEdit = (
    route: RouteFormDataType & { uid: string; removedCheckpoints: DataModel.Checkpoint[] }
  ) => {
    editRouteApi( apiClientInstance )( route ).then( ( res ) => {
      res && setData(data.map((item) => (item.uid !== res.uid ? item : { ...res, no: item.no })));
    });
  };

  const handleDelete = (uid: string) => {
    deleteRouteApi( apiClientInstance.Entity ) (uid ).then( () => {
      setData(data.filter((item) => item.uid !== uid));
    } );
  };

  // Set flag mounted
  useEffect(() => {
    setMounted(true);
  }, []);

  // get routes from server
  useEffect(() => {
    // Call api only 1 time
    if (mounted) {
      getRoutesApi( apiClientInstance ).then((res) => {
        setLoading( false );
        res && setData(res);
      });
    }
  }, [mounted, setData]);

  useEffect(() => {
    // call api get options only when 1 modal opened
    if (!openAddModal && !editingData) return;

    getRouteOptionsApi( apiClientInstance ).then((res) => {
      setOptionLoaded(true);
      res && setOptions( res );
    });
  }, [openAddModal, editingData]);

  return (
    <Box display="flex" flexDirection="column" height="100%" p={4} bgcolor="#1E1E2E">
      <Box display="flex" justifyContent="space-between" alignItems="end" mb={1.875}>
        <Typography fontSize={35} fontWeight={700}>
          Manage Routes
        </Typography>
        
        <Can I="create" a="route">
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ color: "#FFFFFF" }}
            onClick={() => setOpenAddModal(true)}
          >
            + Add Route
          </Button>
        </Can>
        
      </Box>

      <RoutesTable loading={loading} data={data} onEdit={(data) => setEditingData(data)} />

      {openAddModal && (
        <AddRouteModal
          open={openAddModal}
          options={options}
          onAdd={handleAdd}
          onClose={() => setOpenAddModal(false)}
        />
      )}

      {!!editingData && (
        <EditRouteModal
          open={true}
          isOptionLoaded={isOptionLoaded}
          options={options}
          route={editingData}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onClose={() => setEditingData(undefined)}
          getReferences={ getRouteReferences(apiClientInstance) }
        />
      )}
    </Box>
  );
};

export default ManageRoutes;
