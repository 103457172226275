import ReactPdf, { Svg, Path } from "@react-pdf/renderer";

const TimeIcon = (props: ReactPdf.SVGProps) => {
  return (
    <Svg {...(props as any)} viewBox="0 0 16 15">
      <Path
        fill="#000"
        d="M14.407 0.336868C13.9579 -0.112289 13.2297 -0.112289 12.7806 0.336868L7.3719 5.74562L1.96317 0.336868C1.51407 -0.112289 0.785934 -0.112289 0.336823 0.336868C-0.112274 0.785965 -0.112274 1.51411 0.336823 1.96321L5.74556 7.37196L0.336823 12.7807C-0.112274 13.2298 -0.112274 13.9579 0.336823 14.407C0.785934 14.8561 1.51407 14.8561 1.96318 14.407L7.3719 8.99831L12.7806 14.407C13.2297 14.8561 13.9579 14.8561 14.407 14.407C14.8561 13.9579 14.8561 13.2298 14.407 12.7807L8.99825 7.37196L14.407 1.96321C14.8561 1.51411 14.8561 0.785965 14.407 0.336868Z"
      />
    </Svg>
  );
};

export default TimeIcon;
