import { ApiClientType } from "../AdminApi";

import MapCollectionInstance from "./MapCollectionInstance";

export default ( apiClient: ApiClientType ) => (
  {
    collection: ( mapName: string ) => (
      MapCollectionInstance( mapName )( apiClient )
    ),
  }
)