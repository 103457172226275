/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect, useRef } from 'react';
import lifecycle from 'page-lifecycle';

import { Typography, Paper, Box, CircularProgress } from '@mui/material';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';

import webSocketService from './webSocketService';
import getMedia from './getMedia';

type LiveVideoProp = {
  id: string,
  name: string,
  url: string,
  params: string | { [ key: string ]: string },
  selected?: boolean,
  fullHeight?: boolean,
  requestImageCapture ?: { onCapture ?: ( image: string | null ) => void },
  config: any
};

const LiveVideoPlayer = ( {
  id, name, url, params, selected, fullHeight, config, requestImageCapture
}: LiveVideoProp ) => {
  const playerId = `liveplayer-${ id }`;
  const elementRef = useRef<HTMLVideoElement>( null );
  const [ loading, setLoading ] = useState( false );
  const mediaSocket = webSocketService( config );

  useEffect( () => {
    if ( url ) {
      setLoading( true );
      const mediaSource = getMedia( mediaSocket, url, params );
      const videoElement: any = elementRef.current || null;
      if ( videoElement && mediaSource ) {
        videoElement.src = URL.createObjectURL( mediaSource );
        videoElement.load();
        const onPlayReady = ( _: Event ) => {
          setLoading( false );
          videoElement.removeEventListener( 'canplay', onPlayReady );
          videoElement.play();
        };
        videoElement.addEventListener( 'canplay', onPlayReady );
        const onUnload = ( event: any ) => {
          if ( event.newState === 'terminated' || event.newState === 'discarded' ) {
            mediaSocket.close();
          }
        };
        lifecycle.addEventListener( 'statechange', onUnload );
      }
      return () => {
        mediaSocket.close();
        if ( videoElement ) {
          videoElement.pause();
          videoElement.removeAttribute( 'src' );
          videoElement.load();
        }
      };
    }
    return function(){ };
  }, [ url ] );
  
  const videoRef = useRef<HTMLElement>( null );
  const [ isFullScreen, setIsFullScreen ] = useState( false );
  useEffect( () => {
    const fullScreenChangeEventListener = () => {
      const fullScreenElement = document.fullscreenElement;
      setIsFullScreen( !!fullScreenElement );
    }
    document.addEventListener( 'fullscreenchange', fullScreenChangeEventListener );
    return () => document.removeEventListener(
      'fullscreenchange',
      fullScreenChangeEventListener
    );
  }, [ ] );
  const toggleFullScreen = () => {
    if ( document.fullscreenElement !== null ) {
      document.exitFullscreen();
      setIsFullScreen( false );
    } else {
      videoRef.current?.requestFullscreen();
      setIsFullScreen( true );
    }
  }

  useEffect( () => {
    if ( requestImageCapture?.onCapture !== null && requestImageCapture?.onCapture !== undefined ) {
      const imageCanvas = document.createElement( 'canvas' );
      const videoElement = elementRef.current;
      let captured = false;
      if ( videoElement !== null ){
        imageCanvas.width = videoElement.videoHeight;
        imageCanvas.height = videoElement.videoWidth;

        const ctx = imageCanvas.getContext( '2d' );
        if ( ctx ) {
          ctx?.drawImage( videoElement, 0, 0, imageCanvas.width, imageCanvas.height );
          const imageData = imageCanvas.toDataURL( 'iamge/png' );
          const imageStr = imageData.replace( 'data:image/png;base64,', '' );
          captured = true;
          requestImageCapture.onCapture( imageStr );
        }
      }
      if ( !captured ) {
        requestImageCapture.onCapture( null );
      }
    }
  }, [ requestImageCapture ] )

  const videoControlStyle: React.CSSProperties = isFullScreen
    ? { position: 'absolute', bottom: '0px', width: '100%', zIndex: 20000 }
    : { position: 'relative', bottom: '0px', width: '100%', height:'5%', zIndex: 20000 };

  return (
    <Paper sx={{
      display: "flex",
      alignItems: "center",
      overflow: 'hidden',
      boxShadow: selected ? '0px 0px 5px 1px rgba(255,255,255,1)' : 'initial',
      maxHeight: '100%',
      height: fullHeight ? '100%' : 'auto',
      bgcolor: 'black',
      position: "relative"
    }}>
      <Box id={ playerId } sx={{ position: "relative",  flex: "1" }}>
        <Typography
          variant="subtitle1" 
          sx={{
            px: 0.5,
            position: 'absolute',
            top: 25,
            right: 0,
            backgroundColor: '#000000ad',
            zIndex: 1,
            borderTopLeftRadius: 4,
            borderBottomRightRadius: 4,
            fontSize: 24,
          }}>
          { name }
        </Typography>
        <figure ref={ videoRef } data-fullscreen={isFullScreen}>
          <video style={{ width: "100%" }} ref={ elementRef } muted autoPlay />
          <div style={videoControlStyle}>
            <button type="button" data-state="go-fullscreen" style={{ float: 'right' }} onClick={ toggleFullScreen }>
              {
                isFullScreen && (
                  <FullscreenExit />
                )
              }
              {
                !isFullScreen && (
                  <Fullscreen />
                )
              }
            </button>
          </div>
        </figure>
        {
          loading && (
            <CircularProgress
              size={ 24 }
              sx={{
                color: theme => theme.palette.primary.main,
                position: 'absolute',
                top: '50%',
                left: '50%',
                mt: -1.5,
                ml: -1.5,
              }} 
            />
          )
        }
      </Box>
    </Paper>
  );
}

LiveVideoPlayer.defaultProps = {
  selected: false,
  fullHeight: false,
};

export default LiveVideoPlayer;