import { FC, useEffect, useMemo } from "react";

// components
import { Box, IconButton, TableCell } from "@mui/material";
import TablePaper from "../../components/Table/Paper";
import Table from "../../components/Table";
import TablePagination from "../../components/Table/Pagination";
import IconSvg from "../../components/IconSvg";
import { Can } from "../../Context/Ability";

// hooks
import useTableData from "../../hooks/useTableData";

// types
import { Table as TableType } from '../../types/components/Table';
import { RouteDataType } from "./types";

export interface RoutesTableProps {
  loading: boolean;
  data: RouteDataType[];
  onEdit: (data: RouteDataType) => void;
}

const RoutesTable: FC<RoutesTableProps> = ({ loading, data, onEdit }) => {
  const columns = useMemo<TableType.Column<RouteDataType>[]>(() => {
    return [
      {
        id: "no",
        sortable: true,
        header: "NO",
        accessor: "no",
        width: 80,
      },
      {
        id: "name",
        sortable: true,
        header: "NAME",
        accessor: "name",
        width: "25%",
      },
      {
        id: "site",
        sortable: true,
        header: "SITE",
        accessor: (row) => row.site?.name,
        width: "25%",
      },
      {
        id: "routeType",
        sortable: true,
        header: "ROUTE TYPE",
        accessor: (row) => row.routeType?.displayName,
        width: "25%",
      },
      {
        id: "checkpoints",
        sortable: true,
        header: "CHECKPOINTS",
        accessor: (row) => `${row.checkpoints?.length || 0}`,
        width: "25%",
      },
      {
        id: "aciton",
        header: "ACTION",
        renderBodyCell: (row, cellProps) => (
          <TableCell {...cellProps} sx={{ py: 0 }}>
            <Can I="update" a="route">
              <IconButton onClick={() => onEdit(row)}>
                <IconSvg icon="pen" />
              </IconButton>
            </Can>
          </TableCell>
        ),
        width: 180,
        tableCellProps: { align: "right" },
      },
    ];
  }, [onEdit]);

  const { displayData, order, orderBy, setData, handleSort, paginationProps } =
    useTableData<RouteDataType>({
      sort: { order: "asc", orderBy: "no" },
      columns,
    });

  useEffect(() => {
    setData(data);
  }, [data, setData]);

  return (
    <TablePaper>
      <Box flexGrow={1}>
        <Table
          loading={loading}
          dataKey="uid"
          order={order}
          orderBy={orderBy}
          columns={columns}
          data={displayData}
          onSort={handleSort}
        />
      </Box>

      <TablePagination {...paginationProps} />
    </TablePaper>
  );
};

export default RoutesTable;
