export default {
  styleOverrides: {
    root: {
      borderColor: "#444454",

      "&.MuiTableCell-head": {
        lineHeight: "22px",
      },
    },
  },
};