// api instance
import { getCheckpointDetail } from "./getCheckpoint";

// types
import { CheckpointDisplayData } from "../types";

export const getCheckpointsApi = async ( apiInstance: API.Client ): Promise<CheckpointDisplayData[]> => {
  const checkpoints = await apiInstance.Entity.collection( 'checkpoint' ).getAll();

  const checkpointResultsIncludePromise = checkpoints.map(async ( checkpoint: DataModel.Checkpoint ) =>
    getCheckpointDetail( apiInstance )( checkpoint )
  );

  const checkpointsResult = await Promise.all(checkpointResultsIncludePromise);

  return checkpointsResult.map((checkpoint, index) => ({ ...checkpoint, no: index + 1 }));
};
