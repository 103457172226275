import { decode } from "universal-base64url";
import { ApiClientType } from "../AdminApi";

import EntityCollectionInstance from "./EntityCollectionInstance";

export default ( apiClient: ApiClientType ) => (
  {
    ability: () => apiClient.get(
      '/access'
    ).then( response => response.text() )
    .then( abilityB64Text => JSON.parse(
      decode( abilityB64Text )
    ) ).catch( error => {
      console.log( error );
      return [];
    } ),
    collection: ( entityName: string ) => (
      EntityCollectionInstance( entityName )( apiClient )
    ),
  }
)