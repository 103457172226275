import { FC } from "react";
import { Box, BoxProps } from "@mui/material";

type ModalFooterProps = BoxProps

const ModalFooter: FC<ModalFooterProps> = (
  { children, ...props }
) => (
  <Box
      display="flex"
      justifyContent="end"
      px={ 3 }
      py={ 2.5 }
      borderTop="1px solid rgba(255, 255, 255, 0.2)"
      { ...props }
    >
      { children }
    </Box>
);

export default ModalFooter;
