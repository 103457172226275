import ReactPdf, { Svg, Path } from "@react-pdf/renderer";

const TickIcon = (props: ReactPdf.SVGProps) => {
  return (
    <Svg {...(props as any)} viewBox="0 0 16 11">
      <Path
        fill="#000"
        d="M2.0031 4.6875C1.54487 4.22927 0.801914 4.22927 0.34368 4.6875C-0.114565 5.14575 -0.114555 5.88869 0.34368 6.34692L4.55446 10.5577C5.01269 11.0159 5.75564 11.0159 6.21388 10.5577L14.6562 2.1154C15.1144 1.65717 15.1144 0.914215 14.6562 0.455981C14.1979 -0.00225395 13.455 -0.00225395 12.9968 0.455981L5.38417 8.06857L2.0031 4.6875Z"
      />
    </Svg>
  );
};

export default TickIcon;
