import React from "react";
import { Box, Button, Typography } from "@mui/material";
import BackIcon from "@mui/icons-material/ChevronLeft";
import FarwIcon from "@mui/icons-material/NavigateNext";
import Checkbox from "../../components/CheckboxWithLabel";

const CheckList: React.FC<any> = ({
  data, checkpointPtr, totalCheckpoint, handleChangeStep, onListChange 
}) => {
    const checklist = data;
    const [ enableNext, setEnableNext ] = React.useState( true );

    const handleListChange = (idx: number, checked: boolean) => {
      checklist[ idx ].checked = checked;
      onListChange( [ ...checklist ] );
    };

    const handleStep = async () => {
      setEnableNext( false );
      handleChangeStep(
        checkpointPtr === totalCheckpoint - 1 ? 'done' : 'next',
        () => { setEnableNext( true ); }
      )
    }

    const handleStepBack = () => {
      handleChangeStep(
        'prev',
        () => { setEnableNext( true ); }
      );
    }
    return (
      <Box height="100%" minHeight={350} sx={{ display: "flex", flexDirection: "column" }}>
        <Box flexGrow={1} flex="1" p={2.5}>
          <Typography
            sx={{
              fontSize: 18,
              borderBottom: `1px solid rgba(255, 255, 255, 0.2)`,
              pb: 0.5,
            }}
          >
            {data?.stepName}
          </Typography>
          <Box sx={{ overflowY: "auto" }}>
            {checklist.map((item: any, ind: number) => (
              <Checkbox
                ind={ind}
                key={ind}
                onChange={handleListChange}
                label={item.description}
                checked={item.checked}
              />
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
            py: 2,
            px: 2,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {checkpointPtr > 0 && (
            <Button
              variant="contained"
              startIcon={<BackIcon />}
              sx={{
                mr: 0.75,
                background: "#D07F33",
                "&:hover": { background: "#D07F33" },
                "& .MuiButton-startIcon": { mr: 0 },
                px: 2.5,
                py: 1
              }}
              onClick={handleStepBack}
            >
              Back
            </Button>
          )}
          <Button
            variant="contained"
            endIcon={checkpointPtr < totalCheckpoint - 1 && <FarwIcon />}
            onClick={handleStep}
            data-testid="vp-detail-next-btn"
            disabled={!enableNext}
            sx={{
              background:
                checkpointPtr === totalCheckpoint - 1 ? "#DD5151" : "#18AB79",
              "& .MuiButton-endIcon": { ml: 0 },
              "&:hover": {
                background:
                  checkpointPtr === totalCheckpoint - 1 ? "#DD5151" : "#18AB79",
              },
              px: 2.5,
              py: 1
            }}
          >
            {
              checkpointPtr === totalCheckpoint - 1
                ?  (
                  <>Review</>
                )
                : (
                  <>Next</>
                )
            }
          </Button>
        </Box>
      </Box>
    );
};

export default CheckList;
