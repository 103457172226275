export default ( apiInstance: API.Client ) => async ( route: DataModel.Route ) => {
  const { checkpointRefIds } = route;
  const checkpoints = await apiInstance.Entity
    .collection( "checkpoint" )
    .getQuery( { uid: { $in: checkpointRefIds } } );
  
  const cameraRefIds = Array.from(
    new Set( checkpoints.map( ( checkpoint: DataModel.Checkpoint ) => checkpoint.cameraRefId ) )
  );
  const cameraSet = await apiInstance.Entity
    .collection( "camera" )
    .getQuery( { uid: { $in: cameraRefIds } } )
    .then( 
      ( cameras: DataModel.Camera[] ) => cameras.reduce( ( setAcc, camera ) => ( {
        ...setAcc,
        [ camera.uid ]: camera
      } ), { } ) 
    );

  return checkpoints.map( ( checkpoint: DataModel.Checkpoint ) => ( {
    ...checkpoint,
    camera: cameraSet[ checkpoint.cameraRefId ] || null,
  } ) );
}